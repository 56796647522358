import tickLogo from  '../../assets/images/tick.png';
import './LettingFees.css';

const LettingFees = () => {
    return (
        <div className="letting-fees" style={{padding: "6%"}}>
            <h1>Fees</h1>
            <table>
                <thead>
                    <tr>
                        <th>Services</th>
                        <th>Management (8%)</th>
                        <th>Let Only (8%)</th>
                        <th>Let & Rent Collection (10%)</th>
                        <th>Let & Managed (12%)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Professional Photography & Floor Plans</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Comprehensive Property Marketing</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Tenant Identity Verification & Right to Rent</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Obtaining Tenant References</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Drawing up of Tenancy Agreement</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Pre-tenancy Safety Checks</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Pre-tenancy Cleaning</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Arranging EPC’s & Gas Safety Certificates</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Snagging & Handover (new builds only)</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Inventory Check-in / Checkout</td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Deposit Claims and Protection</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Compliance Advice</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Transfer of Utilities</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Dedicated Property Manager</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Management of Service Charge & Ground Rent</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Arranging for Annual Tax Returns</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Rent Collection</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Keyholding Service</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Quarterly Inspection</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Access to Panel of Vetted Contractors</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Arranging Quotes, Repairs & Maintenance</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>24-hour Emergency Contractors</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Check-out Management</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Local Authority License Application Support</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>Rent & Legal Cover (available on request)</td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                        <td></td>
                        <td></td>
                        <td><img src={tickLogo} alt="Tick" /></td>
                    </tr>
                    <tr>
                        <td>(Prices shown <strong>exclude</strong> VAT)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div className="hr"></div>
            <h2>Additional Fees</h2><br />
            <p>
                Renewal Fee: 10% of agreed rent for the total rent. <br />
                Vacant Property Care : £250.00 per month. <br />
                New Tenancy Agreement Fee : £195.00. <br />
                Tenancy Renewal Agreement Fee: £150.00. <br />
                Referencing Fee : £175.00 per person. (Including tenants, permitted occupiers & guarantors, where applicable). <br />
                Deposit Registration Fee: £67.50. <br />
                Inventory Fee: From £175.00 ( Depending on the size of the property). <br />
                Gas Safety Check Fee: £50.00 (plus the cost of the safety check and cost of a gas boiler) <br />
                Portable Appliance Test (PAT) Fee £50.00 (plus the cost of the test) <br />
                Fixed Electrical Wiring Test Fee: £50.00 (plus the cost of the test) <br />
                EPC (Electrical Performance Certificate) Fee £50.00 (plus the cost of the EPC) <br />
                Legionnaires Assessment Fee £50.00 (plus the cost of the assessment) <br />
                Smoke and Carbon Monoxide Alarms Installation: £50.00 (plus the cost of the alarm(s)). <br />
                TDS Dispute Paperwork: £200.00 (per dispute) <br />
                HMO, Additional or Selective License Application Handling Fee: £500.00 (per application) <br />
                Tenancy Cancellation Fee: £500.00. <br />
                Overdue Fees: Subject to interest of 3% and is calculated daily, until payment is made in full. <br />
                Courts and Tribunals appearance: £150.00 per hour. <br />
            </p>
        </div>
    );
}

export default LettingFees;