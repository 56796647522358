const BaseUrl = 'http://209.59.188.109:5002'; // Your base API URL

export async function contactUsApi(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${BaseUrl}/contactUs`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

export async function contactNegotiatorApi(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${BaseUrl}/contactNegotiator`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

export async function contactHelpApi(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(`${BaseUrl}/contactHelp`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}

export async function signupApi(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({data}),
  };
  try {
    const response = await fetch(`${BaseUrl}/signup`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    return { error: error.message };
  }
}


export function isValidEmail(email) {
  // Regular expression for a valid email address
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

  return emailRegex.test(email);
}

export function containsOnlyAlphabetsAndSpaces(input) {
  const regex = /^[A-Za-z\s]+$/;
  return regex.test(input);
}

export function isValidMobileNumber(input) {
  // Regular expression for a mobile number with or without a country code
  const regex = /^(\+\d{1,3}-?)?\d{3,14}$/;

  return regex.test(input);
}