import Bailey from "../assets/images/Bailey.png"
import Beatrice from "../assets/images/Beatrice.png"
import Claudia from "../assets/images/Claudia.png"
export const BreadcrumbHeading=`LOCALLY EXPERT,<br/>GLOBALLY INFORMED`

export const heading = `At Ivory Real Estate, we have a clear mission: Simplicity,<br/> Transparency, & Personality.`

export const Description =[
    `Whether you’re a first-time buyer, seasoned investor or looking for your next rental home, we’re here to make the process seamless and stress-free. We strive to streamline
    the buying, renting, and selling processes, making them easy to navigate for our clients. Our straightforward procedures ensure a hassle-free experience, saving you time and effort`,
    `Transparency is at the heart of everything we do. We believe in open communication and providing our clients with complete and accurate information about our products and services.`,
    `Our commitment to transparency ensures an honest and straightforward experience that puts your satisfaction and
    trust first.`,
    `We understand that each property has its own personality, just like you. Our team of passionate professionals are dedicated to getting to know you and your unique preferences, 
    ensuring we match you with a property that feels like home from the moment you step inside.`,
    `When it comes to selling a property, we take the same personalised approach finding you the perfect buyer who will 
    appreciate and cherish your home just as much as you have.`,
    `We are more than just an estate agency; we are your partners in finding a place 
    where you can create cherished memories and embark on new adventures. 
    Embrace the future of real estate with Ivory, where simplicity, transparency, 
    and personality come together to elevate your property journey to extraordinary heights.`

]

export const SecondSctionHeading="Meet Our Team"

export const SecondSectionDescp = "When it comes to selling a property, we take the same personalised approach finding you the perfect buyer  who will appreciate and cherish your home just as much as you have.";

// export const agents=  
// [
//   "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcTy2uS7pUpDolt5ZkQwUEPi4Pib1nARSkmSeVJgb9Y-McyUemp_GglVxM74fTJMlqh3gCdj94bcLQqL9Hk",
//   "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcRJpevBOQMgZ-Gaeb54y2Tin-o5vsoOtYW2nYtCpzkYqtj6ENWsHD4d1Bv-AUymOggA",
//   "https://cdn.firstcry.com/education/2022/04/25155522/1378635314.jpg",
//   "https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcSh82Dm7OEK7SU7Rnv3Fa-9bi6BZrMM0NJvtm94eGajmxl7mObM7Jp9h3z5UfxaY5IsXGBoB9IX1QMVMlE",
// ];

export const agent =[
  Bailey,
]
