import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container, Button , Image } from 'react-bootstrap';
import logo from '../../assets/images/logo.png'
import logoNew from '../../assets/images/IvoryLogo.svg'
import './Header.css';


const headingtext={
    color: '#FFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    
}


function NavLinkWithHover({ href, text }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Nav.Link
    style={{
      ...(text === 'FREE VALUATION' ? { border: '1px solid #FFFFFF' } : {}),
      ...headingtext,
      color: isHovered ? '#6ca4d0' : headingtext.color,
      transition: 'color 0.3s ease-in-out',
    }}
      href={href}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </Nav.Link>
  );
}

function Header() {
  const [expanded, setExpanded] = useState(false);
  

  const toggleNav = () => {
    setExpanded(!expanded);
  };

  return (
<Navbar expand="lg"  variant="dark" sticky="top" className="justify-content-center" style={{background:"#18192C"}}>
      <Container style={ {alignItems:"flex-end",padding:"20px 03%"}}>
      <Navbar.Brand href="/"  >
          <Image src={logoNew} alt="Your Logo" height="56" />
        </Navbar.Brand>       
         <Navbar.Toggle onClick={toggleNav} aria-controls="responsive-navbar-nav" style={{width:"auto"}}/>
        <Navbar.Collapse id="responsive-navbar-nav" style={{justifyContent:"flex-end"}}>
          <Nav className="mr-auto" style={{gap:"25px"}}>
            <NavLinkWithHover href="/" text="HOME" />
            <NavLinkWithHover href="/about" text="ABOUT" />
            <NavLinkWithHover href="/rent" text="RENT" />
            <NavLinkWithHover href="/sale" text="SALE" />
            <NavLinkWithHover href="/contact" text="CONTACT" />
            <NavLinkWithHover href="https://valuation.ivory-international.com/home/4565"
            target='blank' style={{border: "1px solid #FFFFFF"}}
             text="FREE VALUATION" />

            {/* <Nav.Link href="https://valuation.ivory-international.com/home/4565" target='blank'
             style={{...headingtext, border: "1px solid #FFFFFF" }}>FREE VALUATION</Nav.Link> */}
          </Nav>
          {/* <Nav className="ml-auto">
            <Button variant="outline-light" href="/login">Login</Button>
            <Button variant="primary" href="/signup">Sign Up</Button>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
