import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import 'rc-slider/assets/index.css';
import './Filter.css';

const Filter = ({ typeFilter, selectedFilters, updateSelectedFilters, updateFilteredData }) => {
  const [buy, setBuy] = useState(typeFilter === "Buy" ? true : false);
  // const [rent, setRent] = useState(false);
  const [propertyType, setPropertyType] = useState(null);
  const [propertySize, setPropertySize] = useState(null);
  const [location, setLocation] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  const options = [
    { value: 'flatApartment', label: 'Flat/Apartment' },
    { value: 'house', label: 'House' },
    { value: 'newHomes', label: 'New Homes' },
    { value: 'international', label: 'International Properties' },
    { value: 'investment', label: 'Investment' },
    { value: 'developmentPlot', label: 'Land/Development' },
    // Add more property types as needed
  ];
  const Locationsoptions = [
    { value: 'GB', label: 'London' },
    { value: 'DB', label: 'Dubai' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'SP', label: 'Singapore' },
    // Add more property types as needed
  ];
  const Priceoptions = [
    { value: 'low', label: buy ? 'Less than £500,000' : 'Less than £3,000' },
    { value: 'medium', label: buy ? '£5000,000 - £1,000,0000' : '£3,000 - £5,000' },
    { value: 'high', label: buy ? 'More than £1,000,0000' : 'More than £5,000' },
    // Add more property types as needed
  ];
  const PropertySizeoptions = [
    { value: '1', label: 'One Bedroom' },
    { value: '2', label: 'Two Bedroom' },
    { value: '3', label: 'Three Bedroom' },
    { value: '4', label: 'Four Bedroom' },
    { value: '5', label: 'Five Bedroom+' },
    // { value: '6', label: '6' },
    // { value: '7+', label: '7+' },
    // Add more property types as needed
  ];

  const customSelectStyles = {
    control: (provided,state) => ({
      ...provided,
      minHeight: '58px',
      width: '170px',
      backgroundColor: '#EDEDED',
      borderRightColor: '#B7B8B9',
      borderRadius: '0',
      borderColor: state.isFocused ? 'transparent':'transparent',
      '&:hover': {
        borderColor:'#b3b3b3'
      },
      boxShadow: 'none !important',
      '*': {
        boxShadow: 'none !important',
      },
    }),


    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '8px',
      outlineColor: 'red',
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the border between title and down arrow
    }),
    option: (provided) => ({
      ...provided,
      minHeight: '42px',
      outlineColor: 'red',
      // border: 'none',
    }),
  };

  const customMobileSelectStyles = {
    control: (provided,state) => ({
      ...provided,
      minHeight: '58px',
      width: '170px',
      backgroundColor: '#EDEDED',
      borderRadius: '0',
      borderColor: state.isFocused ? 'transparent':'transparent',
      '&:hover': {
        borderColor:'#b3b3b3'
      },
      boxShadow: 'none !important',
      '*': {
        boxShadow: 'none !important',
      },
    
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the border between title and down arrow
    }),
    option: (provided) => ({
      ...provided,
      minHeight: '42px',
      // border: 'none',
    }),
  };

  const handleSearch = () => {
    // Handle the search based on selected filter options
    const newSelectedFilters = {
      buy: buy,
      propertyType: propertyType !== null ? propertyType.value : null,
      propertySize: propertySize !== null ? propertySize.value : null,
      location: location !== null ? location.value : null,
      maxAmount: maxAmount !== null ? maxAmount.value : null,
    };
    // console.log("Inside filters: ", newSelectedFilters);

    // Update selected filters in the Home component
    updateSelectedFilters(newSelectedFilters);

  };

  //checks device type(mobile/pc)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="filter-text filter-component">
      {typeFilter === "Both" ?
        <div className="buy-sell filter-group">
          <label className="filter-text" style={{ marginRight: "10px" }}>
            <input
              style={{ margin: "10px" }}
              type="radio"
              checked={buy}
              onChange={() => setBuy(!buy)}
            />Buy
          </label>
          <label className="filter-text" style={{ marginRight: "10px" }}>
            <input
              type="radio"
              checked={!buy}
              style={{ margin: "10px" }}
              onChange={() => setBuy(!buy)}
            />Rent
          </label>
        </div> : ""}
      <div style={{ display: 'flex' }}>
        <div className="filter-group">
          <Select
            styles={isMobile ? customMobileSelectStyles : customSelectStyles}
            options={options}
            value={propertyType}
            onChange={(selectedOption) => setPropertyType(selectedOption)} placeholder="Property Type"
          />
        </div>
        <div className="filter-group">
          <Select
            styles={isMobile ? customMobileSelectStyles : customSelectStyles}
            options={PropertySizeoptions}
            value={propertySize}
            onChange={(selectedOption) => setPropertySize(selectedOption)} placeholder="Property Size"
          />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="filter-group">
          <Select
            options={Locationsoptions}
            value={location}
            styles={isMobile ? customMobileSelectStyles : customSelectStyles}
            className="color-dropdown"
            onChange={(selectedOption) => setLocation(selectedOption)} placeholder='Location'
          />
        </div>
        <div className="filter-group">
          <Select
            styles={isMobile ? customMobileSelectStyles : customSelectStyles}
            options={Priceoptions}
            value={maxAmount}
            onChange={(selectedOption) => setMaxAmount(selectedOption)} placeholder='Max Price'
          />
        </div>
      </div>
      <button onClick={handleSearch} style={{width:"25%"}}>SEARCH</button>
    </div>
  );
};

Filter.defaultProps = {
  typeFilter: false
}

export default Filter;
