import { useCallback, useEffect, useState, useRef } from "react";
import ImageCarousel from "../../_component/ImageCarousel/ImageCarousel";
import MapContainer from "../../_component/MapContainer/MapContainer";
import SimilarListings from '../../_component/SimilarListings/SimilarListings';
import { useParams } from 'react-router-dom';
import Overview from "../../_component/Overview/Overview";
import ReadMoreText from "../../_component/ReadMoreText/ReadMoreText";
import Mortgage from "../../_component/Mortgage/Mortgage";
import AgentForm from "../../_component/AgentForm/AgentForm";
import MainDetails from '../../_component/MainDetails/MainDetails';
import './Details.css';

const Details = () => {
  const { propertyId } = useParams();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const apiEndpoint = `http://209.59.188.109:5002/getProperty/?id=${propertyId}`;
  const isInitialLoad = useRef(true);

  const [similarProperties, setSimlarProperties] = useState([]);
  const apiEndpointSimilarListings = `http://209.59.188.109:5002/similarListings/?id=${propertyId}`;

  useEffect(()=>{
    document.body.style.backgroundColor = "#F8F8F8";
  })

  const fetchDetails = useCallback(async () => {
    const response = await fetch(apiEndpoint);
    const jsonResponse = await response.json();
    // console.log(jsonResponse.data);
    setPropertyDetails(jsonResponse.data);
    const nextResponse = await fetch(apiEndpointSimilarListings);
    const data = await nextResponse.json();
    // console.log(data.properties);
    setSimlarProperties(data.properties);
  }, [apiEndpoint, apiEndpointSimilarListings]);

  useEffect(() => {
    if (!isInitialLoad.current) return;
    isInitialLoad.current = false;
      (async () => await fetchDetails())();
      // fetchDetails();
  }, [fetchDetails]);
    return (
      <div className="details-main">
        {propertyDetails && propertyDetails.imgArr && <ImageCarousel pictureUrls={Object.values(propertyDetails.imgArr)} className="image-carousel"/>}
        {propertyDetails && <MainDetails {...propertyDetails}/>}
        <section>
          <article>
            {propertyDetails && <Overview {...propertyDetails} className="details-overview"/>}
            {propertyDetails && 
              (propertyDetails.description || propertyDetails.longDescription) &&
              <ReadMoreText 
                heading={"Description"}
                fullDescription={propertyDetails.description || ""}/>}
            {propertyDetails && 
              propertyDetails.summary &&
              <ReadMoreText 
                heading={"Key Features"}
                flag={false}
                keyFeatures={propertyDetails.summary || []}/>}
            {propertyDetails && 
              propertyDetails.address && 
              propertyDetails.address.geolocation && 
              propertyDetails.address.geolocation.latitude && 
              propertyDetails.address.geolocation.longitude && 
              <MapContainer 
                latitude={propertyDetails.address.geolocation.latitude}
                longitude={propertyDetails.address.geolocation.longitude}/>}
            {/* {propertyDetails && propertyDetails.selling && <Mortgage />} */}
            {similarProperties && <SimilarListings similarProperties={similarProperties}/>}
          </article>
          <aside>
            {propertyDetails && <AgentForm AgentName={propertyDetails.negotiatorsDetails.name}
            AgentDesig={propertyDetails.negotiatorsDetails.jobTitle} 
            AgentContact ={propertyDetails.negotiatorsDetails.mobilePhone}
            AgentID={propertyDetails.negotiatorId} />}
          </aside>
        </section>
      </div>
    );
}

export default Details;