import Breadcrumb from "../../_component/Breadcrumb/Breadcrumb"
import ContactForm from "../../_component/ContactForm/ContactForm"
import Header from "../../_component/Header/Header"
import "./ContactPage.css"
import Footer from '../../_component/Footer/Footer';
import contactImg from '../../assets/images/contact.jpeg'
import MapContainer from "../../_component/MapContainer/MapContainer";

const ContactPage = () => {
    const RenderContactInfo = () => {
        return (
            <div className={"contact-info-container"}>
                <div className="child-container" style={{paddingLeft: "5px"}}>
                    <label className="office-heading" >Head Office London</label>
                    <p className="office-detail">  30 Churchill Place, Canary Wharf, London, E14 5RE <br />
                        <br></br>
                        <b>Phone:</b> +44 207 0888 457 <br />
                        <b>Email:</b> cw@ivory-international.com
                    </p>
                    <label className="office-heading" >Singapore Office</label>
                    <p className="office-detail">  The Signature, 51 Changi Business Park Central 2<br/>
                                                    Level 04-05, 486066, Singapore <br />
                        <br></br>
                        <b>Phone:</b> +65 6701 8500 <br />
                        <b>Email:</b> info@ivory-international.com
                    </p>
                    <label className="office-heading" >Dubai Office</label>
                    <p className="office-detail">  30 Churchill Place, Canary Wharf, London, E14 5RE <br />
                        <br></br>
                        <b>Phone:</b> +44 207 0888 457 <br />
                        <b>Email:</b>info@ivory-international.com
                    </p>
                </div>
                <div className="child-container">
                    <label className="office-heading" >Mayfair Office</label >
                    <p className="office-detail">  30 Churchill Place, Canary Wharf, London, E14 5RE <br />
                        <br></br>
                        <b>Phone:</b>  0207 0888 457 <br />
                        <b>Email:</b> info@ivory-international.com
                    </p>
                    <label className="office-heading" >Hong Kong Office</label>
                    <p className="office-detail">  16f & 17f, 700 Nathan Road, Kowloon, <br/>Hong Kong <br />
                        <br></br>
                        <b>Phone:</b> +852 3960 7000 <br />
                        <b>Email:</b> info@ivory-international.com
                    </p>
                    <label className="office-heading" >Dubai Office</label>
                    <p className="office-detail">  30 Churchill Place, Canary Wharf, London, E14 5RE <br />
                        <br></br>
                        <b>Phone:</b> +44 207 0888 457 <br />
                        <b>Email:</b> info@ivory-international.com
                    </p>
                </div>
            </div>
        )
    }
    return (
        <div>
            <Breadcrumb
                imageUrl={contactImg}
                heading="YOUR PARTNER IN PROPERTY"
            />
            <div className="main-container">
                <RenderContactInfo />
                <ContactForm />
                <MapContainer heading={"Our Office"} latitude={51.50408764579985} longitude={-0.014974830925153995}/>
            </div>
        </div>
    )
}
export default ContactPage;