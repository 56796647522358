import React, { useState } from 'react';
import "./ReadMoreText.css"

function ReadMoreText({ heading, fullDescription ,keyFeatures , flag}) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  console.log( keyFeatures)

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  let trimmedArray
  if(keyFeatures){
  const bulletArray = String(keyFeatures).split('•').filter(item => item.trim() !== '');

  // Trim extra spaces from each item
     trimmedArray = bulletArray.map(item => item.trim());
  }
  const initialDescription = fullDescription.slice(0,300)
  const descriptionToShow = showFullDescription ? fullDescription : initialDescription;

  const formattedDescription = descriptionToShow
  .split('\r\n')
  .map((paragraph, index) => (
    <p className="initial-description" key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
  ));
  
  return (
    <div className="read-more-text">
      <h2 className="desciption-heading">{heading}</h2>
      <hr />
      {flag ? (
        <>
          <p
            className="initial-description"
          >
              {formattedDescription}
          </p>
          {!showFullDescription ? (
            <span
              onClick={toggleDescription}
              className="read-more-button"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              Read More
            </span>
          ) : (
            <span onClick={toggleDescription} className="read-more-button">
              Read less
            </span>
          )}
        </>
      ) : (
        <div className='keyFeature-container'>

          {trimmedArray.map((data, index) => (
            <div key={index}>
              <i className='fa-solid fa-chevron-up fa-rotate-90' style={{color:"#17182B"}}></i>
              <span className='initial-description' > {data} </span>
            </div>
          ))}
              {/* <span className='initial-description' style={{fontSize:"20px"}}> {keyFeatures} </span> */}
        </div>
      )}
    </div>
  );
}

ReadMoreText.defaultProps = {
    keyFeatures: [], // Default to an empty array if not provided
    flag: true,
    fullDescription:"",
  };

export default ReadMoreText;


// import React, { useState } from 'react';
// import "./ReadMoreText.css"

// function ReadMoreText({ heading, initialDescription, fullDescription, keyFeatures, flag }) {
//   const [showFullDescription, setShowFullDescription] = useState(false);

//   const toggleDescription = () => {
//     setShowFullDescription(!showFullDescription);
//   };

//   const descriptionToShow = showFullDescription ? fullDescription : initialDescription;

//   return (
//     <div className="read-more-text">
//       <h2 className='description-heading'>{heading}</h2>
//       <hr />
//       {flag ? (
//         <>
//           <p className={showFullDescription ? 'full-description' : 'initial-description'}>
//             {descriptionToShow}
//           </p>
//           {!showFullDescription ? (
//             <span onClick={toggleDescription} className="read-more-button" style={{ display: "flex", justifyContent: "flex-end" }}>
//               Read More
//             </span>
//           ) : (
//             <span onClick={toggleDescription} className="read-more-button">
//               Read less
//             </span>
//           )}
//         </>
//       ) : (
//         <div>
//           {keyFeatures.map((data, index) => (
//             <div key={index}>
//               {/* <i className='fa-solid fa-chevron-up fa-rotate-90'></i> */}
//               <h1> {data} dad</h1>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// ReadMoreText.defaultProps = {
//   keyFeatures: [], // Default to an empty array if not provided
//   flag: true,
//   initialDescription: "",
//   fullDescription: "",
// };

// export default ReadMoreText;

