import React from 'react';
import './Breadcrumb.css'

function Breadcrumb({ imageUrl, heading, subheading }) {
  const breadcrumbStyle = {
    width: '100%',
    height: '80vh', // Adjust the height as needed
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',

    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  };

  const overlayStyle = {
    background: 'rgba(7, 0, 0, 0.25)', // Updated background color
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const contentStyle = {
    position: 'absolute',
    // bottom: '20px', // Adjust the positioning as needed
    // left: '20px', // Adjust the positioning as needed
    color: '#fff', // Text color
  };

  return (
    <div className="container" style={{ maxWidth:"100%",padding:"0px"}}>
      <div className="row" style={{marginRight:"0px"}}>
        <div className="col-md-12" style={{padding:"0px"}}>
          <div style={breadcrumbStyle}>
          <div style={overlayStyle}></div>

            <div style={contentStyle}>
              <h1 className="main heading" style={{fontFamily:"Montserrat"}} dangerouslySetInnerHTML={{ __html: heading}} />
              {/* <p className='sub heading'>{subheading}</p> */}
              <p className='subheading-breadcrumb' dangerouslySetInnerHTML={{ __html: subheading }} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
