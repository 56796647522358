import React, { useState } from "react";
import './AgentForm.css';
import Claudia from '../../assets/images/Claudia.png'
import Bailey from '../../assets/images/Bailey.png'
import Beatrice from '../../assets/images/Beatrice.png'
import FakeAgent from '../../assets/images/newFakeAgent.jpg'
import { contactNegotiatorApi ,isValidEmail ,containsOnlyAlphabetsAndSpaces} from "../../API";

function AgentForm({AgentName , AgentDesig, AgentContact,AgentID}) {

    const [formData, setFormData] = useState({
        negotiator_id: AgentID,
        name: '',
        email: '',
        phone: '',
        message: '',
      });

      const [isChecked, setIsChecked] = useState(false);
      const [error, setError] = useState(false);


      const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setError(false); // Reset the error when the user checks the checkbox

      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    if(!isChecked){
      setError(true); // Set error to true if checkbox is not checked
      return
    }
        // Call the contactHelpApi function with the formData
        if(formData.name != '' || formData.email != '' ||
            formData.email != '' || formData.phone != '' ||
            formData.message!= '' ){
              if(!isValidEmail(formData.email)){
                alert("Enter a Valid Email")
                return 
              }
              if(formData.name){
                if(!containsOnlyAlphabetsAndSpaces(formData.name)){
                    alert('Enter a valid Name')
                    return
                }
            }
        const result = await contactNegotiatorApi(formData);
    
        // Handle the result or error as needed
        if (!result.error) {
            alert(`Thanks, we will contact you shortly`)
          setFormData({    
            name: '',
            phone: '',
            email: '',
            message: '',})
          // Successful response, you can handle success actions here
          console.log('Form submitted successfully');
        } else {
          // Handle the error here
          console.error('Error submitting the form:', result.error);
        }
    }
      };
    
    

    const AgentImage = AgentName ==='Claudia Popaj' ? Claudia   :
                       AgentName ==='Bailey Roberts' ? Bailey    : 
                       AgentName ==='Beatrice Stefanescu' ? Beatrice  : FakeAgent 
  return (
    <>
      <div className="card" style={{boxShadow:"none",zIndex:"-1"}}>
        <img src={AgentImage} className=" agent-image card-img-top"  />
        <div className="card-body">
          <h5 className="agentForm-title card-title">{AgentName == 0?"John Smith": AgentName}</h5>
          <p className="agentForm-description card-text">
         <div>{AgentDesig}</div>
         {AgentContact?<div>Tel:{AgentContact}</div>:""}
          </p>

        </div>

        <form style={{padding:"3% 6% 6% 6%"}}>
  <div className=" form-group">
    <input type="text" className="form-control" id="formGroupExampleInput" placeholder="  Name" style={{textAlign:"inherit",borderRadius:"0px"}}
    value={formData.name} name="name"
    onChange={handleInputChange} />
  </div>
  <div className="form-group">
    <input type="text" className="form-control" id="formGroupExampleInput2" placeholder="  Phone" style={{textAlign:"inherit",borderRadius:"0px"}}
    value={formData.phone}               name="phone"
    onChange={handleInputChange} />
  </div>
  <div className="form-group">
  <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" style={{textAlign:"inherit",borderRadius:"0px"}}
  value={formData.email} name="email"
  onChange={handleInputChange}/>
  </div>
  <div className="form-group">
  <textarea type="message" className="form-control" id="exampleInputEmail1" placeholder="Message" style={{textAlign:"inherit",borderRadius:"0px"}}
  value={formData.message} name="message"
  onChange={handleInputChange}/>
  </div>
  <div class="form-check" style={{margin:"22px 0px"}}>
  <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"
          checked={isChecked}
          onChange={handleCheckboxChange}/>
  <label class="form-check-label agent-defaultcheck" for="defaultCheck1">
  By submitting to this form I agree to <strong>Terms of use</strong>
    </label>
</div>
{error && <div style={{ color: 'red' }}>Please agree to the Terms of Use</div>}
<a href="#" className="btn btn-primary btnStyle" style={{width:"100%" }}
onClick={handleSubmit}>
        SUBMIT
          </a>
        <div className="btn-container">
<a href="#" className="btn btn-primary btnStyle">
<i className="fa-brands fa-whatsapp" style={{marginRight:"13px"}}/>WhatsApp
          </a>
<a href="tel:+442070888457" className="btn btn-primary btnStyle">
<i className="fa-solid fa-phone"  style={{marginRight:"13px"}} />Call Now
          </a>
          </div>
</form>
      </div>
    </>
  );
}

export default AgentForm;
