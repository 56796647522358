import Breadcrumb from "../../_component/Breadcrumb/Breadcrumb";
import demo from '../../assets/images/newHome.jpg';
import PropertyListing from "../../_component/PropertyListing/PropertyListing";
import BrowseProperty from "../../_component/BrowseProperty/BrowseProperty";
import ContactContainer from '../../_component/ContactContainer/ContactContainer';
import Review from '../../_component/Review/Review';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Filter from "../../_component/Filter/Filter";
import './Home.css';


const Home = () => {
  const [properties, setProperties] = useState([]);
  const cardsPerPage = 9; // Number of cards to load initially and per load more action
  const [offset, setOffset] = useState(0);
  const [totalProperties, setTotalProperties] = useState(-1);
  const isInitialLoad = useRef(true);
  const isFilterMounted = useRef(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    buy: null,
    propertyType: null,
    propertySize: null,
    location: null,
    maxAmount: null,
  });
  // const [selectedFilters, setSelectedFilters] = useState("");

  // Replace this with the actual URL where your FastAPI application is running
  const apiUrl = 'http://209.59.188.109:5002/getProperties/';

  // Define the parameters for pagination (page and limit)
  const limit = cardsPerPage; // Replace with the desired number of items per page

  // Function to fetch data for the next page
  const fetchNextPage = useCallback(() => {
    const currentPageUrl = `${apiUrl}?limit=${limit}&offset=${offset}`;

    fetch(currentPageUrl)
      .then((response) => response.json())
      .then((jsonResponse) => {
        // console.log("Property card data", jsonResponse);
        setProperties((properties) => [...properties, ...jsonResponse.properties]);
        setOffset(offset + limit);
        setTotalProperties(jsonResponse.countOfDocs);
        // console.log("Array length", properties.length, "Total", jsonResponse.count, "Offset", offset);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [offset, limit]);

  useEffect(() => {
    // Fetch property data from the API when the component mounts
    if (!isInitialLoad.current) return;
    isInitialLoad.current = false;
    fetchNextPage();
  }, [fetchNextPage]);

  const updateFilteredData = useCallback((filteredData) => {
    setProperties(filteredData);
  }, []);

  const fetchFilteredData = useCallback((offset) => {
    let filter = `"marketingMode": "${selectedFilters.buy ? "selling" : "letting"}"`;

    // Property Type Filter Query
    // filter += selectedFilters.propertyType !== null ? `,"type": "${selectedFilters.propertyType}"` : "";
    switch (selectedFilters.propertyType) {
      case "flatApartment":
        filter += `,"type": "flatApartment"`;
        break;
      case "house":
        filter += `,"type": "house"`;
        break;
      case "newHomes":
        filter += `,"age": "new"`;
        break;
      case "international":
        filter += `,"address.countryId": {"$ne": "GB"}`;
        break;
      case "investment":
        filter += `,"type": "investment"`;
        break;
      case "developmentPlot":
        filter += `,"$or": [{"type": "land"}, {"type": "developmentPlot"}]`;
        break;
      default:
        // console.log("Invalid property type: ", selectedFilters.propertyType);
    }

    // Property Location Filter Query
    // filter += selectedFilters.location !== null ? `,"address.countryId": "${selectedFilters.location}"` : "";
    switch (selectedFilters.location) {
      case "GB":
        filter += `,"address.countryId": "GB"`;
        break;
      case "DB":
        filter += `,"address.countryId": "DB"`;
        break;
      case "HK":
        filter += `,"address.countryId": "HK"`;
        break;
      case "SP":
        filter += `,"address.countryId": "SP"`;
        break;
      default:
        // console.log("Invalid property location: ", selectedFilters.location);
    }

    // Property Size Filter Query
    switch (selectedFilters.propertySize) {
      case "1":
        filter += `,"bedrooms": 1`;
        break;
      case "2":
        filter += `,"bedrooms": 2`;
        break;
      case "3":
        filter += `,"bedrooms": 3`;
        break;
      case "4":
        filter += `,"bedrooms": 4`;
        break;
      case "5":
        filter += `,"bedrooms": 5`;
        break;
      case "6":
        filter += `,"bedrooms": 6`;
        break;
      case "7+":
        filter += `,"bedrooms": {"$gte": 7}`;
        break;
      default:
        // console.log("Invalid property size: ", selectedFilters.propertySize);
    }

    // filter += selectedFilters.propertySize !== null ? `,"size": "${selectedFilters.propertySize}"` : "";
    if (selectedFilters.buy) {
      switch (selectedFilters.maxAmount) {
        case "low":
          filter += `,"selling.price": { "$lte": 500000 }`;
          break;
        case "medium":
          filter += `,"selling.price": { "$gt": 500000, "$lte": 1000000 }`;
          break;
        case "high":
          filter += `,"selling.price": { "$gt": 1000000 }`;
          break;
        default:
          // console.log("Invalid amount: ", selectedFilters.maxAmount);
      }
    } else {
      switch (selectedFilters.maxAmount) {
        case "low":
          filter += `,"letting.rent": { "$lte": 3000 }`;
          break;
        case "medium":
          filter += `,"letting.rent": { "$gt": 3000, "$lte": 5000 }`;
          break;
        case "high":
          filter += `,"letting.rent": { "$gt": 5000 }`;
          break;
        default:
          // console.log("Invalid amount: ", selectedFilters.maxAmount);
      }
    }
      const currentPageUrl = `${apiUrl}?limit=${limit}&offset=${offset}&filter={${filter}}`;
      // console.log(currentPageUrl);

      fetch(currentPageUrl)
        .then((response) => response.json())
        .then((jsonResponse) => {
            // console.log("Filtered data", jsonResponse.countOfDocs);
            setTotalProperties(jsonResponse.countOfDocs);
            if (offset === 0) {
              setProperties(jsonResponse.properties);
            } else {
              setProperties((properties) => [...properties, ...jsonResponse.properties]);
            }
            setOffset(offset + limit);
        })
        .catch((error) => console.error('Error fetching data:', error));
    },
    [limit, apiUrl, selectedFilters]
  );

  useEffect(() => {
    // console.log("Filter mounted", isFilterMounted.current);
    if (!isFilterMounted.current) {
      isFilterMounted.current = true;
    } else {
      setOffset(0);
      setIsFiltered(true);
      // console.log("SelectedFilter", selectedFilters);
      fetchFilteredData(0);
    }
  }, [fetchFilteredData, selectedFilters]);

  const updateSelectedFilters = (newFilters) => {
    if (JSON.stringify(newFilters) !== JSON.stringify(selectedFilters)) {
      setSelectedFilters(newFilters);
    }
  };

  return (
    <div className="main">
      <Breadcrumb
        imageUrl={demo}
        heading="YOUR PROPERTY OUR PRIORITY"
        // subheading="Whether you’re a first-time buyer, 
        //       seasoned seasoned investor or looking for your next</br> rental home, 
        //       we’re here to make the process seamless and stress-free"
      />
      <div className="homepage-container">
      <Filter selectedFilters={selectedFilters} updateSelectedFilters={updateSelectedFilters} updateFilteredData={updateFilteredData}
      typeFilter={"Both"}/>
      {properties && <PropertyListing 
                  properties={properties} 
                  totalProperties={totalProperties} 
                  fetchNextPage={fetchNextPage} 
                  isFiltered={isFiltered} 
                  fetchFilteredData={fetchFilteredData}
                  flag={true}
                  />}
      <BrowseProperty />
      </div>
      <ContactContainer />
      {/* <Review /> */}
    </div>
  );
}

export default Home;