import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacyPolicy" style={{padding: "6%"}}>
            <h1>
                Ivory Real Estate Privacy policy
            </h1>
            <p>
                If you have any questions about this policy, including any requests to exercise your
                legal rights, please contact us by visiting our Contact page. This policy was last
                updated on 2 August 2023.
                <br />
                This policy was last updated on 2 August 2023.
                <br />
                <ol>
                    <li>
                        <strong>How we collect information</strong>
                        <ul>
                            <li>
                                <strong>When you contact us</strong> either by phone, email, via our 'contact us' page or on
                                social media, we will usually collect your name, email address, position,
                                company name, phone number, the county you operate from and (where
                                applicable) your social media handle because it's in our legitimate interest to
                                make sure we can properly respond to your query.
                            </li>
                            <li>
                                <strong>When you request a demo of our services,</strong> we will collect your name, email
                                address, position, company name, phone number and the county you operate
                                from. We collect this in order to take steps to enter into a contract with you to
                                provide a demo.
                            </li>
                            <li>
                                <strong>When we onboard you as a customer,</strong> we will collect your name, position,
                                email address, phone number, company name and address (if applicable) and
                                your payment details. We collect this in order to take steps to enter into a
                                contract with you and allow you to purchase our services.
                            </li>
                            <li>
                                <strong>When you sign up to receive our news updates.</strong> We will collect your name and
                                email address to provide you with our news updates in line with any
                                preferences you have told us about. When we contact you as a business
                                recipient we rely on legitimate interests to update you about our services and
                                industry insights we believe you will be interested in. When we contact you as a
                                non-business recipient we will only send you our news updates when you have
                                opted-in to receive them and rely on your consent to contact you.
                                <br />
                                You can unsubscribe from our updates at any time by responding to any email
                                you receive from us to tell us you wish to unsubscribe or by visiting
                                our <a href="/contact"><strong>Contact page</strong></a>. Keep if applicable.
                            </li>
                            <li>
                                <strong>When you use our website and consent to our use of cookies</strong>
                                we will collect information about how you use our website. We use this
                                information to improve our website and to better understand how people use
                                it. We only use (and store) non-essential cookies if you provide your consent.
                                More detail on the information we collect and how we do this is set out in our
                                Cookie Policy.
                            </li>
                            <li>
                                <strong>When you attend one of our events or a third-party event we also
                                attend</strong> (including virtual events via video conferencing providers such as
                                Zoom), we will usually collect your name, position, company name and
                                address, email address and phone number. We collect this information
                                because it's in our legitimate interests to promote our business and to know
                                who is attending our events.
                            </li>
                            <li>
                                <strong>When you apply for a job with us</strong> we may collect your name, contact details,
                                recruitment information (e.g. right to work documentation and references),
                                qualifications, accreditations and any additional information we may receive
                                from our recruitment partners or that you might share with us in your
                                application or at interview. We will use this information to assess your
                                suitability for our available roles. We do this to perform our contract
                                obligations or to take steps at your request, before entering into a contract.
                                Where we process your right to work documentation, we will do so to comply
                                with our legal obligations.
                            </li>
                            <li>
                                <strong>If our business is sold.</strong> We process your personal information for this purpose
                                because we have a legitimate interest to ensure our business can be continued
                                by the buyer. If you object to our use of your personal information in this way,
                                the buyer of our business may not be able to provide services to you.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Who we share data with
                        <ul>
                            <li>
                                <strong>Business partners, suppliers and subcontractors</strong> for the performance of the
                                contract we enter into with them or you.
                            </li>
                            <li>
                                <strong>Promotional events and marketing organisations,</strong> we do not sell data for
                                marketing purposes, but may share your data with an event organiser and you
                                will be given the chance to opt-out before we do this.
                            </li>
                            <li>
                                <strong>Regulators/ Authorities/ Enforcement Agencies</strong> if we are under a duty to
                                disclose or share your personal data in order to comply with any legal
                                obligation, or in order to enforce or apply our terms of use and other
                                agreements; or to protect the rights, property, or safety of our clients or others.
                                This includes exchanging information with other companies and
                                organisations for the purposes of fraud protection.
                            </li>
                            <li>
                                <strong>Prospective buyers of our business</strong> under our legitimate interest to ensure
                                our business can be continued by the buyer.
                            </li>
                            <li>
                                What your rights are
                                <br />
                                You have various other rights under applicable data protection laws, including the right to:
                                <ul>
                                    <li><strong>access</strong> your personal data (also known as a “subject access request”);</li>
                                    <li><strong>correct</strong> incomplete or inaccurate data we hold about you;</li>
                                    <li>ask us to <strong>erase</strong> the personal data we hold about you;</li>
                                    <li>ask us to <strong>restrict</strong> our handling of your personal data;</li>
                                    <li>ask us to <strong>transfer</strong> your personal data to a third party;</li>
                                    <li><strong>object</strong> to how we are using your personal data; and</li>
                                    <li><strong>withdraw</strong> your consent to us handling your personal data.</li>
                                </ul>
                                <p>
                                    You also have the right to lodge a complaint with us or the Information
                                    Commissioner's Office, the supervisory authority for data protection issues in
                                    England and Wales.
                                </p>
                                The ICO's address: <br />
                                Information Commissioner's Office <br />
                                Wycliffe House <br />
                                Water Lane <br />
                                Wilmslow <br />
                                Cheshire <br />
                                SK9 5AF
                                <p>
                                    If you are based in the EU you can find your relevant supervisory authority here.
                                    Please keep in mind that privacy law is complicated, and these rights will not always
                                    be available to you all of the time.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ol>
            </p>
        </div>
    );
}

export default PrivacyPolicy;