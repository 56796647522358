import Breadcrumb from "../../_component/Breadcrumb/Breadcrumb";
import Footer from "../../_component/Footer/Footer";
import Header from "../../_component/Header/Header";
import AboutusImg from "../../assets/images/AboutUs.png"
import { BreadcrumbHeading, Description, SecondSctionHeading, SecondSectionDescp, agents, heading } from "../../constant/AboutUsData";
import './AboutUs.css';
import AgentForm from "../../_component/AgentForm/AgentForm";

import Bailey from "../../assets/images/Bailey.png"
import Beatrice from "../../assets/images/Beatrice.png"
import Claudia from "../../assets/images/Claudia.png"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useState } from "react";


const AboutUs=()=>{

  // const carouselData = [
  //   {
  //     image: Bailey,
  //     title: 'Image 1',
  //     description: 'Description for Image 1',
  //   },
  //   {
  //     image: Beatrice,
  //     title: 'Image 2',
  //     description: 'Description for Image 2',
  //   },
  //   {
  //     image: Claudia,
  //     title: 'Image 2',
  //     description: 'Description for Image 2',
  //   },
  //   // Add more images and data as needed
  // ];

    return (
      <>
        <Breadcrumb imageUrl={AboutusImg} heading={BreadcrumbHeading} />

        {/* <div style={{display:"flex"}}> */}
        <div className="container" style={{padding:"4% 0%"}}>
          <div
            className="main-heading"
            dangerouslySetInnerHTML={{ __html: heading }}
          />

          <div className="row">
            {/* <div>
        <h3 className="description">
        Whether you’re a first-time buyer, seasoned investor or looking for your next rental home, we’re here to make the process seamless and stress-free. We strive to streamline
the buying, renting, and selling processes, making them easy to navigate for our clients. Our straightforward procedures ensure a hassle-free experience, saving you time and effort.
 
        </h3>

        <h3>
        Transparency is at the heart of everything we do. 
        We believe in open communication and providing our clients with 
        complete and accurate information about our products and services.        
        </h3>

        <h3>
        Our commitment to transparency ensures an honest and straightforward experience that puts your satisfaction and
trust first.
        </h3>

        <h3>
        We understand that each property has its own personality, 
        just like you. Our team of passionate professionals are dedicated to 
        getting to know you and your unique preferences, ensuring we match you with a 
        property that feels like home from the moment you step inside.
        </h3>
        </div>
        <div>
            <h3>
            When it comes to selling a property, we take the same personalised approach finding you the perfect 
            buyer who will appreciate and cherish your home just as much as you have.
            </h3>
            <h3>
            We are more than just an estate agency; we are your partners in finding a place 
            where you can create cherished memories and embark on new adventures. 
            Embrace the future of real estate with Ivory, where simplicity, transparency, 
            and personality come together to elevate your property journey to extraordinary heights.
            </h3>
        </div> */}
            <div className="col-md-6">
              {Description.slice(0, 4).map((data, index) => (
                <div className="description" key={index}>
                  {data}
                </div>
              ))}
            </div>
            <div className="col-md-6">
              {Description.slice(4).map((data, index) => (
                <div className="description" key={index + 4}>
                  {data}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ background: "rgb(248, 248, 248)", width: "100%",padding:"35px 0px"}}>
          <div
            className="container"
            style={{ background: "#F8F8F8", paddingTopTop: "30px" }}
          >
            <div
              className="main-heading"
              dangerouslySetInnerHTML={{ __html: SecondSctionHeading }}
            />
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: SecondSectionDescp }}
            />
          <div className="row">

          <div className="image-currosel">
            {/* <Carousel showThumbs={false} showStatus={false}>
          {carouselData.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt={item.title} />
              <p className="legend">
                <strong>{item.title}</strong> - {item.description}
              </p>
            </div>
          ))}
        </Carousel> */}
            {/* <img src={Bailey}  style={{borderRadius:"15px"}}/>
        <img src={Beatrice} style={{borderRadius:"15px"}} />
        <img src={Claudia}  style={{borderRadius:"15px"}}/> */}
            <EmployeeCard
              name="Bailey Roberts"
              designation="Senior Lettings Consultant"
              imageSrc={Bailey}
            />
            <EmployeeCard
              name="Beatrice Stefanescu"
              designation="Property Manager"
              imageSrc={Beatrice}
            />
            <EmployeeCard
              name="Claudia Popaj"
              designation="Senior New Homes and Sales Consultant"
              imageSrc={Claudia}
            />
          </div>
          </div>
          </div>

        </div>
      </>
    );

}

export default AboutUs;


const EmployeeCard = ({ name, designation, imageSrc }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}
    >
      <img src={imageSrc} alt={name} style={{ transition: 'transform 0.3s' }} />
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            borderRadius: '5px',
            padding: '5px',
            top: '270px',
            width:'100%'
          }}
        >
          <p className="emply-title" style={{justifyContent:"center",color:"white"}}>{name}</p>
          <p className="emply-subtitle" style={{textAlign:"center"}}>{designation}</p>
        </div>
      )}
    </div>
  );
};