import React from 'react';
import "./BrowseProperty.css";
import ButtonTile from '../ButtonTile/ButtonTile';
import flatsAndAppartmentsImage from '../../assets/images/flats&apartments.jpg'
import housesImage from '../../assets/images/houses.jpg';
import newHomesImage from '../../assets/images/newhomes.jpeg';
import investmentsImage from '../../assets/images/randomProperty.jpg'
import landAndDevelopmentsImage from '../../assets/images/land.jpg';
import internationalImage from '../../assets/images/international.jpeg';

const BrowseProperty = () => {
  const buttons = [
    {
      label: 'Flats & Apartments',
      style: "col-md-6",
      img: flatsAndAppartmentsImage,
      filter: '{"type": "flatApartment"}',
      href: "flatsAndApartments"
    },
    {
      label: 'Houses',
      style: "col-md-3",
      img: housesImage,
      filter: '{"type": "house"}',
      href: "houses"
    },
    {
      label: 'New Homes',
      style: "col-md-3",
      img: newHomesImage,
      filter: '{"age": "new"}',
      href: "newHomes"
    },
    {
      label: 'Investments',
      style: "col-md-3",
      img: investmentsImage,
      filter: '{"type": "investment"}',
      href: "investments"
    },
    {
      label: 'Land and Development',
      style: "col-md-3",
      img: landAndDevelopmentsImage,
      filter: '{"$or": [{"type": "land"}, {"type": "developmentPlot"}]}',
      href: "landAndDevelopment"
    },
    {
      label: 'International',
      style: "col-md-6",
      img: internationalImage,
      filter: '{"address.countryId": {"$ne": "GB"}}',
      href: "international"
    },
  ];
  return (
    <div className="browse-property">
      <h1 className='detail-listing-header'>BROWSE THROUGH OUR <br/>PROPERTY PORTFOLIO</h1>
      <h4 className='detail-listing-text'>Explore our handpicked listings across the city</h4>
      <div className="container browseproperty-container" >
        <div className="row g-3 mb-3">
          <ButtonTile {...buttons[0]} />
          <ButtonTile {...buttons[1]} />
          <ButtonTile {...buttons[2]} />
        </div>
        <div className="row g-3 mb-3">
          <ButtonTile {...buttons[3]} />
          <ButtonTile {...buttons[4]} />
          <ButtonTile {...buttons[5]} />
        </div>
      </div>
    </div>
  );
}

export default BrowseProperty;
