import './MainDetails.css';
import printIcon from '../../assets/images/Layer_2print.svg';
import floorPlanIcon from '../../assets/images/Layer_3FloorPLan.svg';
import shareIcon from '../../assets/images/Layer_3share.svg';
import polygonIcon from '../../assets/images/Polygon 14play.svg';
import ShareModal from '../ShareModal/ShareModal';
import { useState } from 'react';

const MainDetails = (props) => {
    const[showengeryUI , setShowengeryUi] = useState(false)
    const[floorUI , setFloorUi] = useState(false)
    const[shareUI , setShareUi] = useState(false)
    function mergeAddress(addr) {
        const addressParts = [addr.line1, addr.line2, addr.line3, addr.line4].filter(Boolean);
        let address = addressParts.join(', ');
        if (addr.postcode) {
            address +=  ", " + addr.postcode.split(" ")[0];
        }
        return address;
    }

    function formatPrice(price) {
        // Convert the integer to a floating-point number with 2 decimal zeros
        // const formattedPrice = ((price * 100) / 100).toFixed(2);
        const formattedPrice = price;
      
        // Add commas for thousands separators
        const parts = formattedPrice.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
        // Join the parts with the decimal point
        return parts.join('.');
    }

    
    const propertyName = props.address.buildingName !== "" ? props.address.buildingName : props._id;
    const propertyPrice = props.marketingMode === "selling" ? (formatPrice(props.selling.price) || "N/A") : (formatPrice(props.letting.rent) || "N/A");
    // const buildingName = props.address.buildingName ? `${props.address.buildingName} ` : "";
    // const buildingNumber = props.address.buildingNumber ? `${props.address.buildingNumber} ` : "";
    // const building = buildingName + buildingNumber ? buildingName + buildingNumber + ", " : "";

    const address = mergeAddress(props.address);

    const handlePrint = () => {
        window.print();
    };
    const enegryUI =()=>{
        return(
            <div className="modal-overlay" >
            <div className="modal " id="epc-modal" tabindex="-1" aria-labelledby="epcModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Energy Performance Certificates</h5>
                        <button type="button" className="btn-close" onClick={()=>{setShowengeryUi(false);document.body.style.overflow = "auto";}} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <img src={props.epcImg} alt="Energy Performance Certificates" style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
        </div>
        </div>
        )
    }

    const florrplanUi =()=>{
        return(
            <div className="modal-overlay" >
            <div className="modal " id="floor-plan-modal" tabindex="-1" aria-labelledby="floorPlanModalLabel" aria-hidden="true" style={{top:"0%"}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Floor Plan</h5>
                        <button type="button" className="btn-close" onClick={()=>{setFloorUi(false);document.body.style.overflow = "auto";}} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {/* <p>Modal body text goes here.</p> */}
                        <img src={props.Floorplan} alt="Floor Plan" style={{ width: "100%" }} />
                    </div>
                </div>
            </div>
        </div>
        </div>
        )
    }

const sharemodalUI= ()=>{
    return(
        <div className="modal-overlay" >
        <div className="modal " id="share-modal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Share</h5>
                    <button type="button" className="btn-close" onClick={()=>{setShareUi(false);document.body.style.overflow = "auto";}} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ShareModal url={window.location.href} title={"Check out this awesome property!"} />
                </div>
            </div>
        </div>
        </div>
    </div>
    )
} 
    return (
        <div className="main-details">
            <div className="left-side" >
                <div className="top-left">
                    <h1 className='mainDetails-heading'>{address}</h1>
                    {/* <p>{address}</p> */}
                    {/* <h1 className='mainDetails-heading'>{propertyName}</h1>
                    <p>{address}</p> */}
                </div>
                <div className="bottom-left">
                    <h3 style={{color:"#18192C",fontFamily: "Roboto",fontWeight:"500",margin:"0px"}}>&pound;{propertyPrice}</h3>
                </div>
            </div>
            <div className="right-side" >
                <div className="mainDetails-icon right-top">
                    {/* <div className="print gap-maker" onClick={handlePrint}>
                        <text style={{cursor:'pointer'}}>Print </text>
                        <img src={printIcon}  alt="print"/>
                    </div> */}
                    <div className="floor-plan gap-maker" onClick={()=>{setFloorUi(true); document.body.style.overflow = "hidden"}}
                    // data-bs-toggle="modal" data-bs-target="#floor-plan-modal"
                    >
                        <text className='floor-spcl' style={{cursor:'pointer'}}>Floor Plan </text>
                        {/* Floor plan Modal */}
                        {/* <div className="modal fade" id="floor-plan-modal" tabindex="-1" aria-labelledby="floorPlanModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Floor Plan</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={props.Floorplan} alt="Floor Plan" style={{ width: "100%" }} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* Modal end */}
                        <img src={floorPlanIcon}  alt="Floor Plan"/>
                    </div>
                    <div className="share gap-maker" onClick={()=>{setShareUi(true); document.body.style.overflow = "hidden"}}
                    // data-bs-toggle="modal" data-bs-target="#share-modal"
                    >
                        <text style={{cursor:'pointer'}}>Share </text>
                        {/* Floor plan Modal */}
                        {/* <div className="modal fade" id="share-modal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Share</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <ShareModal url={window.location.href} title={"Check out this awesome property!"} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* Modal end */}
                        <img src={shareIcon}  alt="share"/>
                    </div>
                </div>
                <div className=" mainDetails-icon right-bottom" onClick={()=>{setShowengeryUi(true); document.body.style.overflow = "hidden"}}
                // data-bs-toggle="modal" data-bs-target="#epc-modal" 
                style={{display:"flex",justifyContent:"flex-end",gap:"13px",alignItems:"center",cursor:"pointer"}}>
                    <text>Energy Performance Certificates </text>
                    {/* EPC Modal */}
                    {/* <div className="modal fade" id="epc-modal" tabindex="-1" aria-labelledby="epcModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Energy Performance Certificates</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <img src={props.epcImg} alt="Energy Performance Certificates" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* Modal end */}
                    <img src={polygonIcon}  alt="Energy Performance Certificates"/>
                </div>
            </div>

            {showengeryUI && enegryUI()}
            {floorUI && florrplanUi()}
            {shareUI && sharemodalUI()}
        </div>
    );
}

export default MainDetails;