import { Link } from "react-router-dom";
import "./ButtonTile.css";

const ButtonTile = (props) => {
  return (
    <div   className={`${props.style} tile`}>
      <Link to={`/category/${props.href}`}>
        <div className="image" id="portfolio" style={{ backgroundImage: `url('${props.img}')`}}>
          <div id="portfolio" className="centered-text">
            {props.label}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ButtonTile;