import PropertyCard from '../PropertyCard/PropertyCard';
import './PropertyListing.css';

const PropertyListing = (props) => {
  let heading;
  let subHeading;
  
  if (props.totalProperties === -1) {
      switch (props.page) {
        case "Rent":
            subHeading =  "Browse our exclusive properties to rent";
            break;
        case "Sale":
            subHeading =  "Browse our exclusive properties to buy";
            break;
        default:
            subHeading =  "Browse our exclusive properties to rent and buy";
      }
  }
  else if (props.totalProperties === 0) {
    subHeading = "No results found";
  } else {
    switch (props.page) {
        case "flatsAndApartments":
        case "houses":
        case "newHomes":
        case "investments":
        case "landAndDevelopment":
        case "international":
            subHeading = `${props.totalProperties} results found`;
            break;
        case "Rent":
            subHeading =  "Browse our exclusive properties to rent";
            break;
        case "Sale":
            subHeading =  "Browse our exclusive properties to buy";
            break;
        default:
            subHeading =  "Browse our exclusive properties to rent and buy";
      }
  }
  switch (props.page) {
    case "flatsAndApartments":
        heading = "Our Featured Flats And Apartments";
        break;
    case "houses":
        heading = "Our Featured Properties Houses";
        break;
    case "newHomes":
        heading = "Our Featured New Homes";
        break;
    case "investments":
        heading = "Our Featured Investments"
        break;
    case "landAndDevelopment":
        heading = "Our Featured Land And Development"
        break;
    case "international":
        heading = "Our Featured International Properties"
        break;
    case "Rent":
    case "Sale":
    default:
        heading = "Our Featured Properties";
  }

  const loadMoreHandler = props.isFiltered ? props.fetchFilteredData : props.fetchNextPage;

  return (
      <div className="property-listing">
          <div>
            <h1 className="detail-listing-header">{heading}</h1>
            {props.properties && <h4 className="propert-subheading property-listing-text">{subHeading}</h4>}
          </div>
          <div className="container property-cards">
              <div className="tile-container ">
              {props.properties.map((property) => (
                  <PropertyCard key={property.id} propertyDetails={property} flag={props.flag}/>
              ))}
              </div>
          </div>
          <div className=" load-more">
              {props.properties.length < props.totalProperties && (
              <button onClick={loadMoreHandler} style={{backgroundColor:"#18192C"}} className="loadMore-typography btn btn-primary load-more-button property-price">LOAD MORE</button>
              )}
          </div>
      </div>
  )
}

PropertyListing.defaultProps={
    flag:false
}
export default PropertyListing;
