import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Breadcrumb from './_component/Breadcrumb/Breadcrumb';
import Header from './_component/Header/Header';
import Footer from './_component/Footer/Footer';
import TrustpilotWidget from './_component/Trustbox/TrustpilotWidget';
import ReviewsIoWidget from './_component/ReviewsIoWidget/ReviewsIoWidget';
import ReadMoreText from './_component/ReadMoreText/ReadMoreText';
import Mortgage from './_component/Mortgage/Mortgage';
import ImageCarousel from './_component/ImageCarousel/ImageCarousel';
import MapContainer from './_component/MapContainer/MapContainer';
import Home from './_pages/Home/Home';
import AboutUs from './_pages/AboutUs/AboutUs';
import Details from './_pages/Details/Details';
import ContactPage from './_pages/ContactPage/ContactPage';
import Rent from './_pages/Rent/Rent';
import Sale from './_pages/Sale/Sale';
import TermsOfUse from './_pages/TermsOfUse/TermsOfUse';
import CookiePolicy from './_pages/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './_pages/PrivacyPolicy/PrivacyPolicy';
import AccessibilityStatement from './_pages/AccessibilityStatement/AccessibilityStatement';
import LettingFees from './_pages/LettingFees/LettingFees';
import Category from './_pages/Category/Category';

function App() {

   const pictureUrls=  
    [
      "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcTy2uS7pUpDolt5ZkQwUEPi4Pib1nARSkmSeVJgb9Y-McyUemp_GglVxM74fTJMlqh3gCdj94bcLQqL9Hk",
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcRJpevBOQMgZ-Gaeb54y2Tin-o5vsoOtYW2nYtCpzkYqtj6ENWsHD4d1Bv-AUymOggA",
      "https://cdn.firstcry.com/education/2022/04/25155522/1378635314.jpg",
      "https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcSh82Dm7OEK7SU7Rnv3Fa-9bi6BZrMM0NJvtm94eGajmxl7mObM7Jp9h3z5UfxaY5IsXGBoB9IX1QMVMlE",
      "https://www.thesprucepets.com/thmb/1kjJEzrw3adUl0TIOWuf24s5VPw=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/GettyImages-909948608-5c69cd9446e0fb0001560d1a.jpg",
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4"
  ];
  return (
    <div >
      <header >
        <Header />
      </header>
      <main>
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/rent" element={<Rent />} />
            <Route path="/sale" element={<Sale />} />
            <Route path="/details/:propertyId" element={<Details />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/termsOfUse" element={<TermsOfUse />} />
            <Route path="/cookiePolicy" element={<CookiePolicy />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/accessibilityStatement" element={<AccessibilityStatement />} />
            <Route path="/lettingFees" element={<LettingFees />} />
            <Route path="/category/:categoryName" element={<Category />} />
          </Routes>
        </Router>
      </main>
      <footer>
        <Footer />
      </footer>
      {/* <TrustpilotWidget /> */}
      {/* <ReadMoreText heading={"Description"} 
      initialDescription={"Enjoy serenity of Deering Bay whole day from this spectacular North and South, three bedroom, "}
      fullDescription={"Enjoy serenity of Deering Bay whole day from this spectacular North and South, three bedroom, three and a half bath Milano unit with 10ft ceilings which provide light and airy views of the golf course and pool. Oversized open balconies provide luxurious "}
      />


      <Mortgage />

      <ImageCarousel pictureUrls={pictureUrls} />
      <ReadMoreText heading={"Key Features"}
     flag={false}    keyFeatures={["3500 Sq Ft", "24hr Concierge", "Balcony", "Residents gym","Outside heated pool","0.2 mile to Canning Town Station (Jubilee and DLR)"]}     />


     <MapContainer latitude={28.612894} longitude={77.229446} /> */}
    </div>
  );
}

export default App;
