import { useState , useEffect } from "react";
import './ImageCarousel.css'


const ImageCarousel =({ pictureUrls }) =>{

    const [currentIndex, setCurrentIndex] = useState(0);
    const [secondtIndex, setSecondIndex] = useState(0);
    const [thirdIndex, setThirdIndex] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1.0);
    const [isFullView, setIsFullView] = useState(false);

    const[showprev,setShowprev] = useState(false)
    const[shownext,setShownext] = useState(false)

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  //checks device type(mobile/pc)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //changes image on click of next or prev button
    // const [fileChange, setFileChange] = useState({});
    useEffect(() => {
        const totalImages = pictureUrls.length;
        // Update second index based on currentIndex
        setSecondIndex((currentIndex + 1) % totalImages);
        // Update third index based on currentIndex
        setThirdIndex((currentIndex + 2) % totalImages);
      }, [currentIndex, pictureUrls]);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? pictureUrls.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
      console.log('k')
        setCurrentIndex((prevIndex) => (prevIndex === pictureUrls.length - 1 ? 0 : prevIndex + 1));
    };

    const handleEscapeKeyPress = (event) => {
        if (event.key === 'Escape') {
          setIsFullView(false); // Exit full view mode
        }
    };
    
    useEffect(() => {
        // Listen for fullscreen change events
        const handleFullscreenChange = () => {
          setIsFullView(!!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement));
        };
    // Function to handle left arrow key events

        //   const handleLeftArrowKey=(event)=> {
        //   if (event.key === "ArrowLeft") {
        //       // Do something when the left arrow key is pressed
        //       goToPrevious()
        //       // Add your desired functionality here
        //   }
        // }

        // // Function to handle right arrow key events
        // const  handleRightArrowKey=(event)=> {
        //   if (event.key === "ArrowRight") {
        //       // Do something when the right arrow key is pressed
        //       console.log("Right arrow key pressed");
        //       goToNext();
        //       // Add your desired functionality here
        //   }
        // }

        // Adding event listeners for left and right arrow keys

        // document.addEventListener("keydown", handleLeftArrowKey);
        // document.addEventListener("keydown", handleRightArrowKey);

        // Add event listener for fullscreen change
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);
    
        // Clean up event listeners when the component unmounts
        return () => {
          document.removeEventListener('fullscreenchange', handleFullscreenChange);
          document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
          document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
          document.removeEventListener('msfullscreenchange', handleFullscreenChange);


        };
      }, []);

      useEffect(() => {
        // Function to handle arrow key events
        function handleArrowKeys(event) {
          if (event.key === "ArrowLeft") {
            goToPrevious();
          } else if (event.key === "ArrowRight") {
            goToNext();
          }
        }
      
        // Adding event listener for both left and right arrow keys
        document.addEventListener("keydown", handleArrowKeys);
      
        // Rest of your code...
      
        // Clean up event listener when the component unmounts
        return () => {
          document.removeEventListener("keydown", handleArrowKeys);
        };
      }, []);

      useEffect(()=>{
        if(shownext){
          setShowprev(false)
        }
         if(showprev){
          setShownext(false)
        }
      }, [shownext, showprev]);

    const toggleFullView = () => {
        // setIsFullView(!isFullView);
        let element = document.querySelector('.Iframe');
        console.log(element)
        if(!isFullView){
            if (element) {
                if (element.requestFullscreen) {
                  element.requestFullscreen();
                } else if (element.mozRequestFullScreen) { // Firefox
                  element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
                  element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) { // IE/Edge
                  element.msRequestFullscreen();
                }
              }
        }
        else{
            if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
              }
        }
      };

    return(
        <div  className="Iframe">
            <div className={`picture-slider ${isFullView ? 'picture-full-view' : ''} `}>
            <div   className={`${isFullView ? 'full-view-container' : 'normal-container'} `}  style={{width:"100%"}}>
            {isFullView || showprev ?
            <button id='previous-button' onClick={goToPrevious} >
                <i className={`fa fa-chevron-left fa-2x`} style={{color:"#17182B"}} title="Previous Image"/>
                {/* <i className={`fa fa-chevron-left ${currentIndex == 1 ? 'disabled-arow' : ''}`} style={{border: '5px solid #ffffff00'}}></i> */}
            </button> :""}
            <div onMouseOver={()=>{setShowprev(true)}} 
        style={!isFullView ? { paddingRight: "10px", width: isMobile ? "100%" : "65%" } : { }}>
            <img  className={`current-image ${isFullView ? 'full-view1' : ''} `} 
                src={typeof pictureUrls === "string" ? `${pictureUrls}` : `${pictureUrls[currentIndex]}`}
                alt="coming soon" 
            style={{ transform: `scale(${zoomLevel})` }} onClick={()=>{toggleFullView();setIsFullView(true)}}
            />
            {isFullView?
            <div className="image-number">{currentIndex} of {pictureUrls.length}</div>:""}
            </div>
            {!isMobile?<>
            {!isFullView ?
            <div onMouseOver={()=>{setShownext(true)}}   style={{display:"flex",flexDirection:"column",width:"35%"}}>
            <img className={`current-image two-three ${isFullView ? 'full-view1' : ''} `} 
                src={typeof pictureUrls === "string" ? `${pictureUrls}` : `${pictureUrls[secondtIndex]}`}
                alt="coming soon" onClick={()=>{toggleFullView();setIsFullView(true)}}
            style={{ transform: `scale(${zoomLevel})` ,paddingBottom:"5px"}}
            />
            <img className={`current-image two-three ${isFullView ? 'full-view1' : ''} `} 
                src={typeof pictureUrls === "string" ? `${pictureUrls}` : `${pictureUrls[thirdIndex]}`}
                alt="coming soon" onClick={()=>{toggleFullView();setIsFullView(true)}}
            style={{ transform: `scale(${zoomLevel})`,paddingTop:"5px"}}
            />
            </div>: ""}</>:""}
            {isFullView || shownext?
            <button id="next-button" onClick={goToNext} style={isFullView?{right:"0%"}:{right:"10%"}}>
                <i className="fa fa-chevron-right fa-2x" style={{color:"#17182B"}} title="Next Image"/>
            </button> :""}
            </div>
          
            </div>
        </div>
        
    );
}


// ImageCarousel.defaultProps ={
//     fileChange :''
// }

export default ImageCarousel;


