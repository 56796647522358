import React, { useState } from 'react';
import './Footer.css';
import Footer_1 from './../../assets/images/Layer_1logo1.svg';
import Footer_2 from './../../assets/images/Layer_1logo2.svg';
import Footer_3 from './../../assets/images/Layer_1logo3.svg';
import Footer_4 from './../../assets/images/Layer_1logo4.svg';
import facebookIcon from './../../assets/images/facebookIcon.png';
import instagramIcon from './../../assets/images/instagramIcon.png';
import twitterIcon from './../../assets/images/twitterIcon.png';
import messageIcon from './../../assets/images/messageIcon.png';
import linkdonIcon from './../../assets/images/linkedin-icon.svg';
import { isValidEmail, signupApi } from '../../API';
import Modal from '../Modal/Modal';


const margingap = { margin: '20px 0px' }

function Footer() {
  const [email, setEmail] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalMsg , setModalMsg]= useState('')
  const [modalheading , setModalHeading]= useState('')

  const handleInputChange = (event) => {
    setEmail(event.target.value)
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Call the contactHelpApi function with the formData
    if (email != '') {
      if(!isValidEmail(email)){
        setShowModal(true)
        setModalHeading('Oops!')
        setModalMsg('Enter a Valid Email')
        // alert("Enter a Valid Email")
        return 
    }
      const result = await signupApi(email);

      // Handle the result or error as needed
      if (!result.error) {
        setShowModal(true)
        setModalHeading('success')
        setModalMsg('Thanks, we will contact you shortly')
        // alert(`Thanks, we will contact you shortly`)
        setEmail('')
        // Successful response, you can handle success actions here
        console.log('Form submitted successfully');
      } else {
        // Handle the error here
        console.error('Error submitting the form:', result.error);
      }
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <footer style={{ background: "#18192C", color: "white" }}>
      <div className="container" style={{ padding:"0% 1%"}}>
        <div className="row-footer">
          <div className='responsive-container' style={{flexDirection:'row'}}>
            <div className="col-md-3" style={{width:"46%"}}>
              <h5 className='footer-heading' style={{ ...margingap }}>Contact Us</h5>
              <address className='footer-flexgap' style={{ ...margingap, flexDirection: 'column', gap: '15px' }}>
                <div>
                  <i className="fa-solid fa-location-dot" style={{ marginRight: "10px" }} />
                  <span className='footer-subheading'>30 Churchill Place London E14 5RE</span><br />
                </div>
                <div>
                  <i className="fa-solid fa-phone" style={{ marginRight: "10px" }} />
                  <span className='footer-subheading'>+44 207 088 8457</span><br />
                </div>
                <div>
                  <i className="fa fa-envelope" style={{ marginRight: "10px" }} />
                  <span className='footer-subheading'>info@ivory-international.com</span><br />
                </div>
              </address>
            </div>
            <div className="container p-3 newsletter-container"  >
              <h3 className="footer-heading text-white" style={{ ...margingap }}>Sign up for new and off market properties</h3>
              <form action="#" method="Post" className='responsive-container' style={{ marginTop: 11 ,flexDirection:"row"}}>
                <input
                  type="text"
                  name="text" placeholder="" value={email}
                  onChange={handleInputChange}
                  className='newsletter-input'
                  style={{ marginTop: "0px" }}
                />
                <button type="button" onClick={handleSubmit} className='footer-subheading newsletter-button'>SUBMIT</button>
              </form>
            </div>
          </div>

          <div className='responsive-container' style={{ ...margingap }}>
            {/* social icons */}
            <div className='footer-flexgap' style={{ gap: "10px",marginLeft:"-7px",alignItems:"center"}}>
              <a href="https://www.facebook.com/profile.php?id=61550553755839" target="_blank" rel="noopener noreferrer">
                <img className='social-icons' src={facebookIcon} />
              </a>
              <a href="https://www.tiktok.com/@ivoryrealestate" target="_blank" rel="noopener noreferrer">
                <img className='social-icons' src={twitterIcon} />
              </a>
              <a href="https://www.instagram.com/ivory.international" target="_blank" rel="noopener noreferrer">
                <img className='social-icons' src={instagramIcon} />
              </a>
              <a href="https://u.wechat.com/kIo85OXzKzUOuZyvhwnDmGs" target="_blank" rel="noopener noreferrer">
                <img className='social-icons' src={messageIcon} />
              </a>
              <a href="https://www.linkedin.com/company/ivory-real-estate/" target="_blank" rel="noopener noreferrer">
                <img className='social-icons' src={linkdonIcon} style={{width:"30px"}}/>
              </a>
            </div>

            {/* footer images */}
            <div className='picture-container' style={{gap:"10px"}}>
              <img className='picture' src={Footer_1} />
              <img className='picture' src={Footer_2} />
              <img className='picture' src={Footer_4} />
              <img className='picture' src={Footer_3} />
            </div>

          </div>
          <div className='terms-row' style={{ ...margingap }}>
            <div className='footer-mini' >&copy;2023 Ivory Group Real Estate All Right Reserved</div>
            <div className='picture-container'>
              <span className='picture footer-mini'><a href="/termsOfUse">Terms of Use </a></span>
              <span className='picture footer-mini'><a href="/cookiePolicy"> Cookie Policy</a></span>
              <span className='picture footer-mini'><a href="/privacyPolicy"> Privacy Policy</a></span>
              <span className='picture footer-mini '><a className='floor-spcl' href="/accessibilityStatement"> Accessibility Statement</a></span>
              <span className='picture footer-mini'><a href="/lettingFees"> Lettings Fees</a></span>
            </div>

          </div>
        </div>
      </div>
      {showModal && <Modal heading={modalheading} subheading={modalMsg} autoCloseInterval={1000}    
           onClose={closeModal}/>}
    </footer >
  );
}

export default Footer;
