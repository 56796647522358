import React from 'react';
import bedroomIcon from '../../assets/images/bedroomIcon.svg';
import bathroomIcon from '../../assets/images/bathroomIcon.svg';
import parkingIcon from '../../assets/images/parkingIcon.svg';
import frameIcon from '../../assets/images/frame-icon.png';
import "./Overview.css";

const Overview = (props) => {

  // const propertyDetails = props.propertyDetails;
  // console.log("Property details:", props);
  const propertyTypeMapper = (inData) => {
    let outData;
    switch (inData) {
      case "flatApartment":
        outData = "Flat/Apartment";
        break;
      case "house":
        outData = "House";
        break;
      case "bungalow":
        outData = "Bungalow";
        break;
      case "maisonette":
        outData = "Maisonette";
        break;
      case "land":
        outData = "Land";
        break;
      case "farm":
        outData = "Farm";
        break;
      case "cottage":
        outData = "Cottage";
        break;
      case "studio":
        outData = "Studio";
        break;
      case "townhouse":
        outData = "Townhouse";
        break;
      case "developmentPlot":
        outData = "Development Plot";
        break;
      default:
        outData = "";
    }
    return outData;
  }


  return (
    <div className=" overview">
      <h3 className='overview-heading'>Overview</h3>
      <div className="overview-text features">

        {/* first row */}
        <div className='features-row'>
          <div className="property-type">
            <div className="marizinzero overview-heading-text">
              Property Type
            </div>
            <div className="property-type-details" style={{color:"#17182B"}}>
              <div className="overview-heading-text">
                {propertyTypeMapper(props.type[0])}
              </div>
            </div>
          </div>
          <div className="bedrooms">
            <div className="overview-heading-text">
              Bedrooms
            </div>
            <div className="bedroom-details">
              <img src={bedroomIcon} alt="" />
              <div className="bedrooms-count">
                {props.bedrooms}
              </div>
            </div>
          </div>
          <div></div> {/* blank div to maintain design */}
        </div>

        {/* second row */}
        <div className='features-row'>
          <div className="bathrooms">
            <div className="overview-heading-text">
              Bathrooms
            </div>
            <div className="bathroom-details">
              <img src={bathroomIcon} alt="" />
              <div className="bathrooms-count">
                {props.bathrooms}
              </div>
            </div>
          </div>
          <div className="parking">
            <div className="overview-heading-text">
              Parking
            </div>
            <div className="parking-details">
              <img src={parkingIcon} alt="" />
              <div className="parking-spaces-count">
                {props.parking.length || 0}
              </div>
            </div>
          </div>
          <div className="area">
            <div className="overview-heading-text">
              Area
            </div>
            <div className="area-details">
              <img src={frameIcon} style={{ width: "30px" }} alt="" />
              <div className="area-spaces-count">
                {props.internalArea && `${props.internalArea.min} ${props.internalArea.type === "squareFeet" ? "Sq Ft": "Acres"}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
