import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css'; // You can style your modal in Modal.css file

const Modal = ({ heading, subheading, onClose, autoCloseInterval }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, autoCloseInterval);

    return () => {
      clearTimeout(timer);
    };
  }, [autoCloseInterval, onClose]);

  const handleClose = () => {
    clearTimeout();
    onClose();
  };

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{heading}</h2>
          {/* <span className="close-button" onClick={handleClose}>
            &times;
          </span> */}
        </div>
        <div className="modal-content">
          <p>{subheading}</p>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
