import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import './ShareModal.css';

const ShareModal = ({ url, title }) => {
    const shareUrl = url; // URL to share
    const titleToShare = title; // Title to share

    return (
      <div className='share-modal'>
        <div className='facebook-button'>
            <FacebookShareButton url={shareUrl} quote={titleToShare}>
                <FacebookIcon size={32} />
            </FacebookShareButton>
        </div>
        <div className='twitter-button'>
            <TwitterShareButton url={shareUrl} title={titleToShare}>
                <TwitterIcon size={32} />
            </TwitterShareButton>
        </div>
        <div className='whatsapp-button'>
            <WhatsappShareButton url={shareUrl} title={titleToShare}>
                <WhatsappIcon size={32} />
            </WhatsappShareButton>
        </div>
        <div className='email-button'>
            <EmailShareButton url={shareUrl} subject={titleToShare}>
                <EmailIcon size={32} />
            </EmailShareButton>
        </div>
      </div>
    );
};


export default ShareModal;