import logo from '../../assets/images/Logo 1.png';
import './TermsOfUse.css';

const TermsOfUse = () => {
    return (
        <div className="termsOfUse" style={{padding: "6%"}}>
            <h1>
                Terms of use
            </h1>
            <h2>
                Overview - Terms of use
            </h2>
            <p>
                This page (together with the documents referred to on it) tells you the terms of use on which you may use our website
                www.ivory-international.com (“our site”).
            </p>
            <p>
                Please read these terms of use carefully before you start to use our site. By using our site, you indicate that you accept these terms of use and that you agree to abide by them. If you do not agree to these terms of use, please refrain from using our site. These terms of use apply to all users of, and visitors to, our site.
            </p>
            <h2>
                Other applicable terms
            </h2>
            <p>
                These terms of use refer to the following additional terms, which also apply to your use of our site:our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to the collection, use, transfer and processing of your information and you warrant that all data provided by you to us is accurate; and our Cookie Policy, which sets out information about the cookies on our site.
            </p>
            <h2>
                Information about us
            </h2>
            <p>
                Our site is operated by Ivory Real Estate (“We”, “Us”, “Our”). Ivory Real Estate is a registered limited liability partnership registered in England and Wales with number 13734574 and registered office: 30 Churchill Place Canary Wharf E14 5RE We carry out business at the following addresses:
            </p>
            <h3>London Offices:</h3>
            <p>30 Churchill Place Canary Wharf E14 5RE</p>
            <h2>
                Accessing our site
            </h2>
            <p>
                We permit access to our site on a temporary basis, and we reserve the right to withdraw or amend the service we provide on our site without notice (see below). We will not be liable if for any reason our site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of our site, or our entire site.The information on our site is only available in English.
            </p>
            <p>
                We are committed to ensuring that the pages within our site are accessible to all individuals including those with disabilities. Throughout the design process, we have undertaken to ensure that our site meets accessibility standards as defined by the World Wide Web Consortium (W3C). All English language pages of our site conform to W3C’s “Web Content Accessibility Guidelines 1.0”, Level 1.
            </p>
            <p>
                If you experience any technical or other issues with our website, please info@ivory-international.com
            </p>
            <h2>
                Intellectual property rights
            </h2>
            <p>
                We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Copyright laws and treaties around the world protect those works published on our site. All such rights are reserved.
            </p>
            <p>
                No part of this website may be published, distributed, extracted, re-utilised, or reproduced in any material or electronic form except in accordance with the permissions set out below or as permitted by the Copyright Designs and Patents Act 1988 or the Copyright and Rights in Databases Regulations 1997, as applicable. <br />
                You may print off one copy, and may download extracts, of any page(s) from our site for your personal reference and you may draw the attention of others within your organisation to material posted on our site. <br />
                You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. <br />
                Our status (and that of any identified contributors) as the authors of material on our site must always be acknowledged.You must not use any part of the materials on our site for commercial purposes without obtaining a licence to do so from us or our licensors.If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
            </p>
            <h2>
                Reliance on information posted
            </h2>
            <p>
                Commentary and other materials posted on our site are provided for general information purposes only and do not constitute legal or other professional advice on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by you or any visitor to our site, or by anyone who may be informed of any of its contents.
            </p>
            <h2>
                Electronic communications
            </h2>
            <p>
                Information that you send to us in electronic form may not be secure and, unless you are already a customer, it will not be treated as privileged or confidential. If you are in any doubt about sending us personal data electronically, please send it in encrypted form.
            </p>
            <h2>
                Client Relationships
            </h2>
            <p>
                We do not intend that any relationship between you and Ivory Real Estate is or may be created by your access to or use of our site or any information contained herein.If you wish us to act for you, it is important to ensure we can do so formally, by completing our standard engagement process; and we reserve the right to decline if it would create a conflict of interest with any of our current or former clients. <br />
                If you submit information about a particular matter to us by email or otherwise without completing our engagement process, you acknowledge that it will not be considered to be confidential.
            </p>
            <h2>
                Our liability
            </h2>
            <p>
                The material displayed on our site is provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by law, we hereby expressly exclude:all conditions, warranties, representations and other terms which might otherwise be implied by statute, common law or the law of equity in relation to our site or any content on it; and any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with our site or in connection with the use, inability to use, or results of the use of our site, any websites linked to it and any materials posted on it, including, without limitation any liability for: <br />
                loss of income or revenue; loss of business; loss of profits or contracts; loss of anticipated savings; loss of data; loss of goodwill; wasted management or office time; and for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable. <br />
                This does not affect our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law.
            </p>
            <h2>
                Linking to our site
            </h2>
            <p>
                You may link to our home page, provided you do so in a way that is fair and legal and does not damage or infringe our intellectual property or reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.You must not establish a link from any website that is not owned by you. <br />
                Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page. We reserve the right to withdraw linking permission without notice. If you wish to make any use of material on our site other than that set out above, please address your request to www.ivory-international.com
            </p>
            <h2>
                Links from our site
            </h2>
            <p>
                Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them.
            </p>
            <h2>
                Our site changes regularly
            </h2>
            <p>
                We aim to update our site regularly, and may change the content at any time. If the need arises, we may suspend access to our site, or close it indefinitely. Any of the material on our site may be out of date at any given time, and we are under no obligation to update such material.
            </p>
            <h2>
                Emails
            </h2>
            <p>
                Please note that when we send you an email message, the information contained in it (or any attachment) is likely to be confidential. It is intended only for the person named in the email. In the event that you receive a message in error, please immediately notify us and delete it. Any dissemination, distribution, copying, disclosure or use of any email message or its contents unless authorised by us is strictly prohibited. <br />
                We cannot guarantee the integrity or suitability of any email for your computer. It is possible that our message to you might contain destructive programmes known as Viruses or Worms and we would advise you that we do not accept any liability for consequential effects caused to your computer, or others connected to it and that you should determine the likelihood of such content yourself.
            </p>
            <h2>
                Severability
            </h2>
            <p>
                If a provision of these terms of use, or any of the additional terms referred to, herein, is or becomes illegal, invalid or unenforceable in any jurisdiction in which these terms of use are intended to be effective, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part provision shall be deemed deleted. Any modification to or deletion of a provision or part of a provision under this clause shall not affect: <br />
                · the legality, validity or enforceability in that jurisdiction of any other provision of these terms of use or of another page of the Legal Notices; or· the legality, validity or enforceability in other jurisdictions of that or any other provision of these terms of use or any of the additional terms referred to, herein.
            </p>
            <h2>
                Jurisdiction and applicable law
            </h2>
            <p>
                The English courts will have exclusive jurisdiction over any claim arising from, or related to, a visit to our site although we retain the right to bring proceedings against you for breach of these conditions in your country of residence or any other relevant country.These terms of use and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England.
            </p>
            <h2>
                Viruses
            </h2>
            <p>
                We do not guarantee that our site will be secure or free from bugs or viruses. <br />
                You are responsible for configuring your information technology, computer programs and platform in order to access our site. You should use your own virus protection software. <br />
                You must not misuse our site by knowingly introducing viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.
            </p>
            <h2>
                Trade Marks
            </h2>
            <img src={logo} alt="Ivory International Logo" /><br />
            <p>
                the Logo is a registered trade mark of Ivory Real Estate. <br />
                Variations We may revise these terms of use at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we have made, as they are binding on you.
            </p>
            <p>
                And finally If there shall be any conflict between the provisions of these terms of use and the provisions of our Privacy Policy and Cookie Policy then the provisions of these terms of use shall prevail. <br />
                Thank you for visiting our site.
            </p>
        </div>
    );
}

export default TermsOfUse;