import { useState } from 'react';
import './ContactForm.css'
import { contactHelpApi, isValidEmail ,containsOnlyAlphabetsAndSpaces} from '../../API';
import { object } from 'prop-types';

const ContactForm = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        message: '',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      //   function to validate the form data before sending it to API
      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
    if(formData.email != '' || formData.message != '' 
    ||formData.firstName != '' ||formData.lastName != ''  ){
      if(!isValidEmail(formData.email)){
        alert("Enter a Valid Email")
        return 
      }
      if(formData.firstName){
        if(!containsOnlyAlphabetsAndSpaces(formData.firstName)){
            alert('Enter a valid First Name')
            return
        }
    }
    if(formData.lastName){
        if(!containsOnlyAlphabetsAndSpaces(formData.lastName)){
            alert('Enter a valid last Name')
            return;
        }
    }
    if(formData.city){
        if(!containsOnlyAlphabetsAndSpaces(formData.city)){
            alert("Enter a valid city");
            return;
        }
    }
        // Call the contactHelpApi function with the formData
        const result = await contactHelpApi(formData);
    
        // Handle the result or error as needed
        if (!result.error) {
          // Successful response, you can handle success actions here
          alert(`Thanks, we will contact you shortly`)
          setFormData({    
          firstName: '',
          lastName: '',
          email: '',
          contactNumber: '',
          message: '',})
          console.log('Form submitted successfully');
        } else {
          // Handle the error here
          console.error('Error submitting the form:', result.error);
        }
    }
      };
    return (
        <div className="parent-container">
            <label className="form-title" >Whatever your needs, we have someone that can help.</label>
            <label className="form-subtitle" >Please complete the form below and we will respond to your enquiry as soon as possible.</label>
            <form >
                <div className='input-div'>
                    <div style={{ width: '100%', paddingRight: 16 }}>
                        <label className="form-input-label">First Name</label>
                        <input className="input-field-small" type="text"
                         name="firstName"
                         value={formData.firstName}
                         onChange={handleInputChange} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label className="form-input-label">Last Name</label>
                        <input className="input-field-small" type="text"
                         name="lastName"
                         value={formData.lastName}
                         onChange={handleInputChange} />
                    </div>
                </div>
                <div className='input-div'>
                    <div style={{ width: '100%', paddingRight: 16 }}>
                        <label className="form-input-label">Email</label>
                        <input className="input-field-small" type="text"
                         name="email"
                         value={formData.email}
                         onChange={handleInputChange}/>
                    </div>
                    <div style={{ width: '100%' }}>
                        <label className="form-input-label">Contact Number</label>
                        <input className="input-field-small" type="text"
                         name="contactNumber"
                         value={formData.contactNumber}
                         onChange={handleInputChange} />
                    </div>
                </div>
                <label style={{ marginLeft: 8, marginBottom: 8, fontSize: 20, fontWeight: 500 }}>Message</label>
                <input className="message-input-field" type="text" 
                         name="message"
                         value={formData.message}
                         onChange={handleInputChange} />
                <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                    <input className="submit-button" type="submit" value="SUBMIT" 
                    onClick={handleSubmit}/>
                </div>
            </form>
        </div>
    );
}
export default ContactForm;