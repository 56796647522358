import './AccessibilityStatement.css';

const AccessibilityStatement = () => {
    return (
        <div className="accessibility" style={{padding: "6%"}}>
            <h1>
                Accessibility Statement for Ivory Real Estate
            </h1>
            <p>
                Ivory Real Estate is committed to ensuring digital accessibility for people with
                disabilities. We are continually improving the user experience for everyone, and
                applying the relevant accessibility standards.
            </p>
            <h2>
                Measures to support accessibility
            </h2>
            <p>
                Ivory Real Estate takes the following measures to ensure accessibility of
                <strong><a href="www.ivory-international.com"> www.ivory-international.com</a></strong>
                <ul style={{listStyleType: "disc"}}>
                    <li>
                        Include accessibility as part of our mission statement.
                    </li>
                    <li>
                        Integrate accessibility into our procurement practices.
                    </li>
                    <li>
                        Appoint an accessibility officer and/or ombudsperson.
                    </li>
                    <li>
                        Provide continual accessibility training for our staff.
                    </li>
                    <li>
                        Include people with disabilities in our design personas.
                    </li>
                </ul>
            </p>
            <h2>
                Conformance status
            </h2>
            <p>
                The Web Content Accessibility Guidelines (WCAG) defines requirements for designers
                and developers to improve accessibility for people with disabilities. It defines three
                levels of conformance: Level A, Level AA, and Level AAA. Ivory Real Estate is partially
                conformant with WCAG 2.1 level AA. Partially conformant means that some parts of the
                content do not fully conform to the accessibility standard.
            </p>
            <h2>
                Additional accessibility considerations
            </h2>
            <p>
                Although our goal is WCAG 2.1 Level AA conformance, we have also applied some Level
                AAA Success Criteria: Images of text are only used for decorative purposes. Re-
                authentication after a session expires does not cause loss of data. Some videos have
                sign language interpretation.
                Keep if you will have these features on your website
            </p>
            <h2>
                Feedback
            </h2>
            <p>
                We welcome your feedback on the accessibility of the Ivory Real Estate website..
                Please let us know if you encounter accessibility barriers on
                <strong><a href="www.ivory-international.com"> www.ivory-international.com</a></strong>
                <ul style={{listStyleType: "disc"}}>
                    <li>
                        Phone:
                    </li>
                    <li>
                        E-mail:
                    </li>
                    <li>
                        Postal address:
                    </li>
                </ul>
                We try to respond to feedback within 5 business days.
            </p>
            <h2>
                Technical specifications
            </h2>
            <p>
                Accessibility of Ivory Real Estate relies on the following technologies to work with the
                particular combination of web browser and any assistive technologies or plugins
                installed on your computer:
                <ul style={{listStyleType: "disc"}}>
                    <li>
                        HTML
                    </li>
                    <li>
                        WAI-ARIA
                    </li>
                    <li>
                        CSS
                    </li>
                    <li>
                        JavaScript [SP1]
                    </li>
                </ul>
                    <strong>
                        These technologies are relied upon for conformance with the accessibility
                        standards used.
                        Formal complaints
                        We aim to respond to accessibility feedback within 5 business days, and to
                        propose a solution within 10 business days. You are entitled to escalate a
                        complaint to the national authority, should you be dissatisfied with our response
                        to you.
                    </strong>
            </p>
        </div>
    );
}

export default AccessibilityStatement;