import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { heading } from '../../constant/AboutUsData';

const MapContainer = ({heading, latitude, longitude }) => {
  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const defaultCenter = {
    lat: latitude,
    lng: longitude,
  };



  return (
    <div className="read-more-text">
    <h2 className="desciption-heading">Map</h2>
    <hr />
    <LoadScript googleMapsApiKey="AIzaSyCDInHXQ8ChsLRTbvmpleSzla7VC5nDjiU">
      <GoogleMap mapContainerStyle={mapStyles} zoom={14} center={defaultCenter}>
        <Marker position={{ lat: latitude, lng: longitude }} />
      </GoogleMap>
    </LoadScript>
    </div>
  );
};

MapContainer.defaultProps ={
  heading:"",
}

export default MapContainer;
