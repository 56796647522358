import PropertyCard from "../PropertyCard/PropertyCard";

const SimilarListings = (props) => {
  return (
    <div className="similar-listings" style={{padding:"2%"}}>
      <h1 className="desciption-heading similar-listings-header" style={{marginTop:"2%",textAlign:"left"}}>Similar Listings</h1>
      <hr className="custom-hr"></hr>
      <div className="container " style={{padding:"0px",maxWidth:'100%'}}>
      <div className="tile-container-simmilar" >
          {props.similarProperties.map((property) => (
              <PropertyCard key={property.id} propertyDetails={property} />
          ))}
        </div>
      </div>
    </div>
  ); 
}

export default SimilarListings;
