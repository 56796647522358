// PropertyCard.js
import React from 'react';
import bedroomIcon from '../../assets/images/bedroomIcon.svg';
import bathroomIcon from '../../assets/images/bathroomIcon.svg';
import parkingIcon from '../../assets/images/parkingIcon.svg';
import "./PropertyCard.css";
import propertyImage from "../../assets/images/randomProperty.jpg";
import {Link} from 'react-router-dom';

const PropertyCard = (props) => {
  const propertyDetails = props.propertyDetails;
  // const cardStyle = {
  //   width: "380px",
  //   height: "425px",
  //   background: "#FFF",
  //   boxShadow: "0px 4px 10px 0px rgba(71, 100, 195, 0.10)"
  // };

  function getBuildingName(addr) {
    const postCode = addr.postcode ? addr.postcode.split(" ")[0]: "";
    const addressParts = [addr.buildingName, addr.line1, postCode].filter(Boolean);
    return addressParts.join(' ');
  }

  function formatPrice(price) {
    // Convert the integer to a floating-point number with 2 decimal zeros
    const formattedPrice = ((price * 100) / 100).toFixed(2);
  
    // Add commas for thousands separators
    const parts = formattedPrice.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Join the parts with the decimal point
    return parts.join('.');
  }

  const price = propertyDetails.marketingMode === "selling" ? (formatPrice(propertyDetails.selling.price) || "N/A") : (formatPrice(propertyDetails.letting.rent) || "N/A");

  const buildingName = getBuildingName(propertyDetails.address);
  
  return (
    <div className="card mx-auto cardStyle" >
      {/* <img className="card-img-top" src={propertyDetails.images.href} alt="" /> */}
      <img className="card-img-top" src={propertyDetails.featuredImageUrl || propertyImage} alt="" href={`/details/${propertyDetails._id}`} />
      {props.flag ?
      <div class="card-img-overlay" 
          style={propertyDetails.marketingMode === "letting" ?{background:"#6ca4d0",color:"#FFF"}:{background:"#18192c",color:"#FFF"}}>
        {/* <div class="text-box"> */}
          <p class="card-text">{propertyDetails.marketingMode === "letting" ? "For Rent" : "For Sale"}</p>
        {/* </div> */}
      </div>:""}
      <div className="card-body property-details">
        <a href={`/details/${propertyDetails._id}`} class="stretched-link">
          <h2 className="card-title">{buildingName}</h2>
        </a>
        <h6 className="property-price">&pound; {price}</h6>
        <hr />
        <div className="features">
          <div className="bedrooms">
            <div className="bedroom-text">
            </div>
            <div className="bedroom-details">
              <img src={bedroomIcon} alt="" style={{width:"50%"}}/>
              <div className="bedrooms-count">
                {propertyDetails.bedrooms}
              </div>
            </div>
          </div>
          <div className="bathrooms">
            <div className="bathroom-text">
            </div>
            <div className="bathroom-details">
              <img src={bathroomIcon} alt=""  style={{width:"50%"}}/>
              <div className="bathrooms-count">
                {propertyDetails.bathrooms || 0}
              </div>
            </div>
          </div>
          <div className="parking">
            <div className="parking-text">
            </div>
            <div className="parking-details">
              <img src={parkingIcon} alt=""  style={{width:"50%"}}/>
              <div className="parking-spaces-count">
                {propertyDetails.parking.length || 0}
              </div>
            </div>
          </div>
          <div className="property-details">
            <Link to={`/details/${propertyDetails._id}`}>View details</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
