import { useState } from "react";
import "./ContactContainer.css"
import { contactUsApi, containsOnlyAlphabetsAndSpaces, isValidEmail } from "../../API";
import Modal from "../Modal/Modal";

const ContactContainer = (props) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        city: '',
        postcode: '',
      });

      const [showModal, setShowModal] = useState(false)
      const [modalMsg , setModalMsg]= useState('')
      const [modalheading , setModalHeading]= useState('')
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const closeModal = () => {
        setShowModal(false);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
        if(formData.email != '' || formData.city != '' 
        ||formData.firstName != '' ||formData.lastName != '' 
         ||formData.postcode != ''  ){
        // Call the contactHelpApi function with the formData
        // if((formData.email != '') && (formData.firstName != '') && (formData.lastName != '')){
        //     alert("Enter the")
        //     return
        // }
            if(!isValidEmail(formData.email)){
                setShowModal(true)
                setModalHeading('Oops!')
                setModalMsg('Enter a Valid Email')
                // alert("Enter a Valid Email")
                return 
            }
            if(formData.firstName){
                if(!containsOnlyAlphabetsAndSpaces(formData.firstName)){
                    setShowModal(true)
                    setModalHeading('Oops!')
                    setModalMsg('Enter a valid First Name')
                    // alert('Enter a valid First Name')
                    return
                }
            }
            if(formData.lastName){
                if(!containsOnlyAlphabetsAndSpaces(formData.lastName)){
                    setShowModal(true)
                    setModalHeading('Oops!')
                    setModalMsg('Enter a valid last Name')
                    // alert('Enter a valid last Name')
                    return;
                }
            }
            if(formData.city){
                if(!containsOnlyAlphabetsAndSpaces(formData.city)){
                    setShowModal(true)
                    setModalHeading('Oops!')
                    setModalMsg('Enter a valid city')
                    // alert("Enter a valid city");
                    return;
                }
            }

                const result = await contactUsApi(formData);
            
                // Handle the result or error as needed
                if (!result.error) {
                    setShowModal(true)
                    setModalHeading('Oops!')
                    setModalMsg('Thanks, we will contact you shortly')
                    // alert(`Thanks, we will contact you shortly`)

                    setFormData({        
                    firstName: '',
                    lastName: '',
                    email: '',
                    address: '',
                    city: '',
                    postcode: '',})
                // Successful response, you can handle success actions here
                console.log('Form submitted successfully');
                } else {
                // Handle the error here
                console.error('Error submitting the form:', result.error);
                }
        }
      };
    return (
        <div className="parent-card-style">
            <div className="child-card-style">
                <div style={{padding:"2.5%"}}>
                <h1 className="tile-heading title-style"
                style={{marginBottom:"20px"}}> Contact us for a free valuation</h1>
                <form>
                    <div className="form">
                        <div className="first-line">
                            <div className="address input-form-field">
                                <label className='contact-subtitle' htmlFor="address">Address</label>
                                <input className="address-input-field" type="text" name="address" id="name"
                                value={formData.address}
                                onChange={handleInputChange}
                            />
                            </div>
                            <div className="city input-form-field">
                                <label className='contact-subtitle'  htmlFor="city">City</label>
                                <input className="small-input-field" type="text" id="city" name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                            />
                            </div>
                            <div className="postcode input-form-field">
                                <label className='contact-subtitle'  htmlFor="postcode">Postcode</label>
                                <input className="small-input-field" type="text" id="postcode" name="postcode"       
                                value={formData.postcode}
                                onChange={handleInputChange}
                            />
                            </div>
                        </div>
                        <div className="second-line">
                            <div className="firstName input-form-field">
                                <label className='contact-subtitle'  htmlFor="firstName">First Name</label>
                                <input className="small-input-field" type="text" id="firstName" name="firstName" 
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                            </div>
                            <div className="lastName input-form-field">
                                <label className='contact-subtitle'  htmlFor="lastName">Last Name</label>
                                <input className="small-input-field" type="text" id="lastName" name="lastName" 
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                            </div>
                            <div className="contact-email input-form-field">
                                <label className='contact-subtitle'  htmlFor="contactEmail">Email</label>
                                <input className="email-input-field" type="email" id="contactEmail" 
                                style={{padding:"5px",height:"auto"}} name="email"
                                value={formData.email}
                                onChange={handleInputChange} />
                            </div>
                            <div className="button">
                                <input className="contact-container submit-button" type="submit" value="SUBMIT" 
                                style={{height:"36px",border:"transparent",width:"100%"}} 
                                onClick={handleSubmit}/>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            {showModal && <Modal heading={modalheading} subheading={modalMsg} autoCloseInterval={1000}    
           onClose={closeModal}/>}
        </div>
    );
};

export default ContactContainer;