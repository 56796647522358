import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import PropertyListing from '../../_component/PropertyListing/PropertyListing';
import "./Category.css";


const Category = () => {
    const { categoryName } = useParams();
    const cardsPerPage = 18; // Number of cards to load initially and per load more action
    const [properties, setProperties] = useState([]);
    const [offset, setOffset] = useState(0);
    const [totalProperties, setTotalProperties] = useState(0);
    const isInitialLoad = useRef(true);
    const [gotResult, setGotResult] = useState(false);
    const categoryToFilterMapping = {
        flatsAndApartments: '{"type": "flatApartment"}',
        houses: '{"type": "house"}',
        newHomes: '{"age": "new"}',
        investments: '{"type": "investment"}',
        landAndDevelopment: '{"$or": [{"type": "land"}, {"type": "developmentPlot"}]}',
        international: '{"address.countryId": {"$ne": "GB"}}'
    };

    // Replace this with the actual URL where your FastAPI application is running
    const apiUrl = `http://209.59.188.109:5002/getProperties/?filter=${categoryToFilterMapping[categoryName]}`;
    console.log(apiUrl);
    // Define the parameters for pagination (page and limit)
    const limit = cardsPerPage; // Replace with the desired number of items per page

    // Function to fetch data for the next page
    const fetchNextPage = useCallback(() => {
    const currentPageUrl = `${apiUrl}&limit=${limit}&offset=${offset}`;

    fetch(currentPageUrl)
        .then((response) => response.json())
        .then((data) => {
            console.log("Property card data", data);
            setProperties((properties) => [...properties, ...data.properties]);
            setOffset(offset + limit);
            setTotalProperties(data.countOfDocs);
            setGotResult(true);
        })
        .catch((error) => console.error('Error fetching data:', error));
  }, [offset, limit, apiUrl]);

    useEffect(() => {
        // Fetch property data from the API when the component mounts
        if (!isInitialLoad.current) return;
        isInitialLoad.current = false;
        fetchNextPage();
    }, [fetchNextPage]);
    
    return (
        <div className="category-main">
            {gotResult && 
                <PropertyListing 
                    properties={properties} 
                    totalProperties={totalProperties} 
                    clickHandler={fetchNextPage}
                    page={categoryName}/>}
        </div>
    );
}

export default Category;